<template>
	<v-container fluid class="pa-5">
		<v-row class="text-center">
			<v-col cols="12">
				Audit Log
			</v-col>
			<v-col cols="12">
				<Log/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import Log from '@/components/hipaa/Log'

export default {
	name: "Hipaa",

	components: {
		Log
	},
	data: () => ({
	}),
};
</script>
