<template>
	<v-container fluid class="pa-0" align-content-start fill-height>
		<v-row v-if="$vuetify.breakpoint.mdAndUp" class="fill-height"> 
			<v-col cols="0" class="py-0">
				<Inputs/>
			</v-col>
			<v-col cols="12" md="6">
				<Summary/>
			</v-col>
			<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="py-0">
				<Outputs/>
			</v-col>
		</v-row>
		<v-row v-if="$vuetify.breakpoint.smAndDown" class="fill-height py-12"> 
			<v-col cols="12" class="text-overline">
				Configuration not available on small screen devices.
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" :max-width="1150" persistent>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab bottom right fixed small color="primary" @click="dialog=true">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>
			<InstallIntegration @close="dialog=false"/>
		</v-dialog>
	</v-container>
</template>

<script>
import Summary from '@/components/configure/Summary';
import Inputs from '@/components/configure/Inputs';
import Outputs from '@/components/configure/Outputs';
import InstallIntegration from '@/components/configure/InstallIntegration';

export default {
	name: "Home",

	components: {
		Summary,
		Inputs,
		Outputs,
		InstallIntegration
	},

	created () {
		this.$store.dispatch('configuration/load')
	},

	data: () => ({
		dialog: false
	}),
};
</script>
