<template>
	<v-autocomplete 
		v-model="model" 
		:readonly="readonly" 
		return-object 
		item-text="name"
    	item-value="name"
		:items="items"
		:filter="filterObject"
		placeholder="Start typing to Search"
        prepend-icon="mdi-account-search"
		:messages="message"
	>
		<template v-slot:selection="{ item }">
			{{name(item)}}
		</template>
		<template v-slot:item="{ item, on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-content class="text-left" dense>
					<v-list-item-title>
						{{name(item)}}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{item.properties.email}}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			
			
		</template>
	</v-autocomplete>
</template>

<script>

import _ from 'lodash';
import EntityNameHelper from '@/components/util/EntityNameHelper';

export default {
	name: "EntitySelector",
	props: {
		items: Array,
		value: Object,
		suggested: Object,
		readonly: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		model: {
			get()
			{
				if(!this.value && this.suggested)
				{
					const match = _.find(this.items, ['entityId', this.suggested.entity.entityId]);
					this.$emit('input', match);
					return match;
				}
				return this.value;
			},
			set(value)
			{
				this.$emit('input', value);
			}
			
		},
		message()
		{
			return (this.suggested) ? 'Suggested Match' : null;
		}
	},
	methods: {
		name(item)
		{
			if(!item)
			{
				return;
			}
			return EntityNameHelper.get(item) || item.externalKey || item.entityId;
		},
		
		filterObject(item, queryText, itemText) {
			const name = this.name(item);
			return name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
		}
	},
	data: () => ({
	}),
}

</script>

<style>
.integration-selector-img {
	max-height: 24px;
	object-fit: contain;
	margin-right: 20px;
}
</style>
