<template>
	<v-container fluid class="pa-5 text-left">
		<v-row>
			<v-col cols="10" class="text-center">
				User & Location Matching 
			</v-col>
		</v-row>
		<v-card>
			<DialogError ref="error"/>
			<v-card-title align="left">
				{{item.name}}
			</v-card-title>
			<v-card-text>
				<v-row v-if="!edit">
					<v-col v-html="current" class="text-body-1" />
					<v-btn icon @click="edit = true">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-row>
				<v-row v-if="edit">
					<v-col cols="12">
						Select a configuration:
						<v-radio-group v-model="model" column>
							<v-radio label="System of Record" value="source"/>
							<v-radio label="Rule Based Match" value="rule" />
							<v-radio label="Manually Matched" value="manual" />
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row>
					<v-col v-html="description"/>
				</v-row>
			</v-card-text>
			<v-card-actions v-if="edit">
				<v-btn text :disabled="!model" @click="edit = false">Cancel</v-btn>
				<v-spacer/>
				<v-btn text :disabled="!model" @click="save">Save</v-btn>
			</v-card-actions>
			<v-card-actions v-if="!edit">
				<v-spacer/>
				<v-btn text color="primary" @click="resync">Re-Sync</v-btn>
			</v-card-actions>
		</v-card>
		<v-card v-if="(model === 'rule' || model === 'manual') && !edit" class="mt-5">
			<v-tabs v-model="tab" grow>
				<v-tab>Users</v-tab>
				<v-tab>Locations</v-tab>
			</v-tabs>
			<Rules v-if="model === 'rule' && !edit" :item="item" :type="type" />
			<Manual v-if="model === 'manual' && !edit" :item="item" :type="type" />
		</v-card>
	</v-container>
	
</template>

<script>

import Rules from '@/components/users/Rules';
import Manual from '@/components/users/Manual';
import DialogError from '@/components/util/DialogError';


export default {
	name: "Details",
	props: {
		item: Object
	},

	components: {
		Rules,
		Manual,
		DialogError
	},

	mounted()
	{
		this.reload();
	},

	watch: {
		item()
		{
			this.reload();
		}
	},

	computed: {

		type()
		{
			return (this.tab === 1) ? 'CENSUS_LOCATION' : 'CENSUS_USER';
		},

		current()
		{
			switch(this.model)
			{
				case 'source':
					return `Configured as System of Record`;
				case 'rule':
					return `Configured for Rule Based Matching`;
				case 'manual':
					return `Configured for Manual Matching`;
				default:
					return 'This integration is not currently configured';
			}
		},
		description()
		{
			switch(this.model)
			{
				case 'source':
					return `Users created by this integration will be automatically added to FusionOS. 
							All others integrations will match to these records.<br>
							<strong>Only one system should be configured at the system of record.</strong>`;
				case 'rule':
					return `Rules based matching will be used to connect users from this integration with records created by another integration.`;
				case 'manual':
					return `Users from this integration will be manually matched to records created by another integration.<br>
							<strong> Manual user matching is not recommended, and should only be used if rule based matching is not possible.`;

				default:
					return '';
			}
		}
	},

	methods: {
		async reload()
		{
			this.model = this.item.matchType;
			this.edit = !this.model;
		},

		async save()
		{
			await this.$store.dispatch('configuration/setMatchingType', { integrationId: this.item.integrationId, type: this.model });
			this.reload();
		},

		async resync()
		{

			try {
				await this.$store.dispatch('entities/sync', this.item.integrationId);
			} catch(e) {
				if(e.message === 'Too often')
				{
					this.$refs.error.show('Sync can only be called every 15 mins. Please try again later.');
				} else {
					throw e;
				}

				return;
			}

			this.$refs.error.show('Sync operation submitted.', 'success');

		}
	},

	data: () => ({
		tab: 0,
		model: null,
		edit: false
	}),
};
</script>
