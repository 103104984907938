<template>
	<v-footer dark>
		<v-spacer/>
		<v-btn href="https://www.k4connect.com/privacy" target="_blank" class="mx-5 caption" text>
			Privacy
		</v-btn>
		<v-btn href="https://www.k4connect.com/legal-sla/" target="_blank" class="mx-5 caption" text>
			Service-Level Agreement
		</v-btn>
		<v-btn href="mailto:support@k4connect.com" target="_blank" class="mx-5 caption" text>
			support@k4connect.com
		</v-btn>
		<v-spacer/>
	</v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>