<template>
	<v-simple-table class="text-left">
		<thead>
			<tr>
				<th v-for="header in headers" :key="header">{{header}}</th>
			</tr>
      	</thead>
		<tbody>
			<tr v-for="log in logs" :key="log._id">
				<td>{{log.integrationName}}</td>
				<td>{{log.targetIntegrationName}}</td>
				<td>{{log.purpose}}</td>
				<td>{{log.userName}}</td>
				<td>{{log.authorized | formatDatetime }}</td>
			</tr>
		</tbody>
	</v-simple-table>
</template>

<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState('hipaa', [ 'logs' ])
	},
	mounted()
	{
		this.$store.dispatch('hipaa/load');
	},
	data: () => ({
		headers: ["Source", "Destination", "Purpose", "User", "Date"],
	}),
};
</script>

<style>
</style>