<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Deploy {{item.name}} {{version}} build {{build}}</v-card-title>
		<v-card-text class="text-left">
			<v-row>
				Are you sure you want to deploy this version?
			</v-row>
			<v-row>
				If the available configuration and credentials options have changed in the new version, they will need to be configured properly in the Configure section of the dashboard.
			</v-row>
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>
		<v-card-actions>
			<v-btn text :disabled="loading" @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" :disabled="loading" @click="save">Deploy</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "DeployIntegration",

	props: {
		item: Object,
		version: String,
		build: Number
	},

	components: {
		DialogError
	},

	methods: {
		async save()
		{
			this.loading = true;

			const { integrationId } = this.item;
			const { version, build } = this;
			try {
				await this.$store.dispatch('configuration/upgradeIntegration', {  integrationId, version, build });
			} catch(e) {
				//console.error(e);
				this.$refs.error.show('Could not deploy integration. Please try again later.');
				this.loading = false;
				return;
			}

			this.close();

		},

		close()
		{
			this.loading = false;
			this.selected = null;
			this.$emit('close');
		}
	},

	data: () => ({
		loading: false
	}),
};
</script>