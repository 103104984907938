<template>
	<v-chip 
		:id="id" 
		x-small 
		@mouseover="over" 
		@mouseleave="leave"
	>
		{{schema.connections.length}}
	</v-chip>
</template>

<script>

import Connections from '@/components/util/Connections';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
	name: "ConnectionsChip",

	props: {
		type: String,
		item: Object,
		schema: Object
	},

	computed: {

		...mapGetters('configuration', ['getIntegration']),
		id()
		{
			return `${this.type}-${this.item.integrationId}-${this.schema.cls}`;
		}
	},
	methods: {
		over()
		{
			let target = (this.type === 'input') ? 'output' : 'input';

			let visible = [];

			let from = [{ id: this.id }];	
			
			let to = [];

			_.each(this.schema.connections, (connection)=> {
				const integration = this.getIntegration(connection);

				if(integration.enabled)
				{
					to.push({
						id: `${target}-${connection}-${this.schema.cls}`,
						color: '#ff6600'
					});
				} else {
					//if disabled, just point to card
					to.push({
						id: `${target}-${connection}`,
						color: '#ccc'
					});
				}
		
				visible.push(connection);
			})

			if(this.type === 'input')
			{
				this.$store.dispatch('configuration/outputsVisible', visible);
				this.$nextTick(()=>{
					Connections.show(from, to);
				});
			} else {
				this.$store.dispatch('configuration/inputsVisible', visible);
				this.$nextTick(()=>{
					Connections.show(to, from);
				});
				
			}

			

		},
		leave()
		{
			Connections.hideAll();
			this.$store.dispatch('configuration/inputsVisible', null);
			this.$store.dispatch('configuration/outputsVisible', null);
		},
	},

	data: () => ({
	}),
};
</script>
