import _ from 'lodash';

import auth from '@/api/auth';
import axios from 'axios';
import env from '@/api/env';
import router from '@/router/index';
import store from '@/store/index';

const baseUrl = env.api();
const baseUrlWebsocket = env.websocket();
const baseUrlSchemas = env.schemas();

const FIFTEEN_MINUTES_MS = 15 * 60 * 1000;

axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response && error.response.status === 401) {
		router.push('/logout');
	}
	return Promise.reject(error);
});

export default {

	//Auth and user

	async getUserInfo(){
        
		const { data } = await this.get(`https://${auth.cognito.getAppWebDomain()}/oauth2/userInfo`, {}, 'Bearer ');
		return data;
    },

	async getCurrentUser(){
        
		const { data } = await this.get(`${baseUrl}/auth/currentUser`);
		return data;
    },

	async getEnv(){
        
		//use axios direct, this is used during bootstrap before authentication
		const { data } = await axios.get(`${baseUrl}/auth/data`);
		return data;
    },

	//List integrations

	async getAvailableIntegrations()
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/available`);
		return data;
	},

	async getInstalledIntegrations()
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/installed`);
		return data;
	},

	//Enable integrations

	async installIntegration(integrationId, version, build)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/integrations`, { integrationId, version, build });
	},

	async enableIntegration(integrationId, settings, credentials)
	{
		const organizationId = store.getters['user/organizationId'];

		if(settings)
		{
			await this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/configuration`, settings);
		}

		if(credentials)
		{
			await this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/credentials`, credentials);
		}

		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/enable`, {});
	},

	async getStatus(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/status`);
		return data;
	},

	async getSettings(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/configuration`);
		return data;
	},

	async getCredentials(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/credentials`);
		return data;
	},

	async getKeyValue(integrationId, key)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/keyValue/${key}`);
		return data;
	},

	async setKeyValue(integrationId, key, value)
	{
		const organizationId = store.getters['user/organizationId'];
		value = String(value);
		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/keyValue`, { key, value });
	},

	//Disable integrations

	async disableIntegration(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/disable`, {});
	},

	async removeIntegration(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.delete(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}`);
	},

	//upgrade integrations

	async upgradeIntegration(integrationId, version, build)
	{
		const organizationId = store.getters['user/organizationId'];

		try {
			await this.disableIntegration(integrationId);
		} catch(e) {
			//can be already disabled
			if(e.response.status !== 200 && e.response.status !== 409)
			{
				throw e;
			}
		}
		
		await this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/version`, { version, build });
		await this.enableIntegration(integrationId);

	},

	//sync

	async sync(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		let last;

		try {
			last = await this.getKeyValue(integrationId, "lastSyncTime");
			
		} catch(e) {
			//not found is ok
			if(e.response.status !== 404)
			{
				throw e;
			}
		}

		if(last)
		{
			if(Date.now() - parseInt(last.value) < FIFTEEN_MINUTES_MS)
			{
				throw new Error("Too often");
			}
		}

		await this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/sendSync`);
		return this.setKeyValue(integrationId, "lastSyncTime", Date.now());
	},

	//Connections

	async addConnection(from, to, cls)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/linkIntegrations`, {
			fromIntegrationId: from,
			cls,
			toIntegrationId: to
		});
	},

	async deleteConnection(from, to, cls)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.delete(`${baseUrl}/organization/${organizationId}/linkIntegrations`, {
			data: {
				fromIntegrationId: from,
				cls,
				toIntegrationId: to
			}
		});
	},

	//Stats

	
	async getStats()
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/statistics/organizations/${organizationId}`);
		return data;
	},

	async getState(integrationId)
	{
		const organizationId = store.getters['user/organizationId']
		return this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/oauth/state`);
	},

	//Entities

	async getEntitiesByType(type)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/entities?type=${type}`);
		return data;
	},

	async getEntitiesByIntegration(integrationId)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/entities`);
		return data;
	},

	//Manual matching

	async setMatchingType(integrationId, type)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/match`, { matchType: type });
	},

	async getCandidates(integrationId, type, archived = false)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/candidates?type=${type}&showArchived=${archived}`);
		return data;
	},

	async getMatched(integrationId, type)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/entities?type=${type}`);
		return data;
	},

	async matchCandidate(integrationId, externalKey, entityId)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/entities/${entityId}/mergeCandidate`, {
			externalKey
		});
	},

	async unlinkCandidate(integrationId, entityId)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.delete(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/entities/${entityId}`);
	},

	async getSuggested(integrationId, type)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/candidates/suggestMatches?type=${type}`);
		return data;
	},

	//History

	async getHistory(cls, start, end, more)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/queueMessages?class=${cls}&startTime=${start}&endTime=${end}${ (more) ? '&startFile=' + more : '' }`);
		return data;
	},

	//Rules

	async getRules(type)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/rules?type=${type}`);
		return data;
	},

	async addRule(sourceIntegrationId, sourceField, targetIntegrationId, targetField, type)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/rules`, { sourceIntegrationId, sourceField, targetIntegrationId, targetField, type });
	},

	async removeRule(sourceIntegrationId, sourceField, targetIntegrationId, targetField, type)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.delete(`${baseUrl}/organization/${organizationId}/rules`, {
			data: { sourceIntegrationId, sourceField, targetIntegrationId, targetField, type }
		});
	},

	//Developer

	async addIntegration(name, description)
	{
		return this.post(`${baseUrl}/integrations`, { name, description });
	},

	async updateIntegration(integrationId, name, description)
	{
		return this.put(`${baseUrl}/integrations/${integrationId}`, { name, description });
	},

	async uploadImage(integrationId, type, data)
	{
		
		const formData = new FormData();	
		formData.append(type, data);

		const requestData = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		};

		return this.post(`${baseUrl}/integrations/${integrationId}/${type}`, formData, requestData);
	},

	async inject(cls, payload)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/inject`, { cls, payload });
	},

	getLogWebsocket(integrationId)
	{
		const jwtToken = auth.cognito.getSignInUserSession().getAccessToken().jwtToken;
		const organizationId = store.getters['user/organizationId'];
		const url = `${baseUrlWebsocket}/organization/${organizationId}/integrations/${integrationId}/logs?authToken=${jwtToken}`;
		return new WebSocket(url);

	},

	async getLogs(integrationId, start, end)
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/integrations/${integrationId}/logHistory?startTime=${start.toISOString()}&endTime=${end.toISOString()}`);
		return data;
	},

	//schemas

	async getSchemas()
	{
		const { data } =  await this.get(`${baseUrlSchemas}/manifest.json`);
		return data;
	},

	async getSchema(cls)
	{
		const { data } =  await this.get(`${baseUrlSchemas}/standalone/${cls}.json`);
		return data;
	},

	async getMocks()
	{
		const { data } =  await this.get(`${baseUrlSchemas}/mocks.json`);
		return data;
	},

	//hipaa

	async getHipaa()
	{
		const organizationId = store.getters['user/organizationId'];
		const { data } = await this.get(`${baseUrl}/organization/${organizationId}/hipaaRetrieveLog`);
		return data;
	},

	async logConnection(from, to, purpose)
	{
		const organizationId = store.getters['user/organizationId'];
		return this.post(`${baseUrl}/organization/${organizationId}/integrations/${from}/hipaaRecordLog`, {
			purpose,
			targetIntegrationId: to
		});
	},


	//Helper functions

	async get(url, requestData = {}, authPrefix = '')
	{
		const jwtToken = auth.cognito.getSignInUserSession().getAccessToken().jwtToken;
		
		requestData.headers = requestData.headers || {};
		requestData.headers['Authorization'] = authPrefix + jwtToken;

		return axios.get(url, requestData);
		
	},

	async post(url, data, requestData = {}, authPrefix = '')
	{
		const jwtToken = auth.cognito.getSignInUserSession().getAccessToken().jwtToken;
		
		requestData.headers = requestData.headers || {};
		requestData.headers['Authorization'] = authPrefix + jwtToken;

		return axios.post(url, data, requestData);
		
	},

	async put(url, data, requestData = {}, authPrefix = '')
	{
		const jwtToken = auth.cognito.getSignInUserSession().getAccessToken().jwtToken;
		
		requestData.headers = requestData.headers || {};
		requestData.headers['Authorization'] = authPrefix + jwtToken;

		return axios.put(url, data, requestData);
		
	},

	async delete(url, requestData = {}, authPrefix = '')
	{
		const jwtToken = auth.cognito.getSignInUserSession().getAccessToken().jwtToken;
		
		requestData.headers = requestData.headers || {};
		requestData.headers['Authorization'] = authPrefix + jwtToken;


		return axios.delete(url, requestData);
		
	},
}

