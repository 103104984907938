<template>
	<v-row>
		<v-col cols="1">
			<v-icon>mdi-circle-small</v-icon>
		</v-col>
		<v-col cols="10" class="pa-1">
			<div class="v-subheader theme--light pa-0">When {{connection.schemas.input.name}} is received from {{connection.input.name}}, {{connection.schemas.output.name}} will be called on {{connection.output.name}} </div>
			<div v-if="!connection.input.enabled || !connection.output.enabled" class="text-overline grey--text">Disabled</div>
		</v-col>
	</v-row>
</template>

<script>

export default {
	name: "Summary",

	props: {
		connection: Object
	},

	data: () => ({
	}),
};
</script>
