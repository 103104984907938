import config from "@/api/config";

export default {

	async init()
	{
		const { userPoolId, appClientId } = await config.getEnv();
		this.userPoolId = userPoolId;
		this.appClientId = appClientId;
	},

	api()
	{
		const suffix = this.suffix();

		return `https://api${suffix}.fusionos.io/v1/config`;
		
	},

	websocket()
	{
		const suffix = this.suffix();

		return `wss://api${suffix}.fusionos.io/v1/config`;
		
	},

	schemas()
	{
		const suffix = this.suffix();

		return `https://schemas${suffix}.fusionos.io/schemas`;
		
	},

	auth()
	{

		const suffix = this.suffix();

		switch(suffix)
		{
			case '-dev':
				if(location.href.indexOf('localhost') > -1)
				{
					return {
						UserPoolId: this.userPoolId,
						AppWebDomain: `login${suffix}.fusionos.io`,
						ClientId: this.appClientId,
						...this.console(`localhost:8080`, 'http')
					}
				} else {
					return {
						UserPoolId: this.userPoolId,
						AppWebDomain: `login${suffix}.fusionos.io`,
						ClientId: this.appClientId,
						...this.console(`dashboard${suffix}.fusionos.io`)
					}
				}
			case '-staging':
			case '-sandbox':
				return {
					UserPoolId: this.userPoolId,
					AppWebDomain: `login${suffix}.fusionos.io`,
					ClientId: this.appClientId,
					...this.console(`dashboard${suffix}.fusionos.io`)
				}
			default:
				return {
					UserPoolId: this.userPoolId,
					AppWebDomain: `login.fusionos.io`,
					ClientId: this.appClientId,
					...this.console(`dashboard.fusionos.io`)
				}
				
			
		}
	},

	console(domain, protocol = 'https')
	{
		return {
			RedirectUriSignIn: `${protocol}://${domain}/login/oauth2/code/cognito`,
			RedirectUriSignOut: `${protocol}://${domain}/logout`,
			AppWebUrl: `${protocol}://${domain}`
		}
	},

	suffix()
	{
		const name = this.name();
		return (name === 'production') ? '' : `-${name}`;
 		
	},

	name()
	{
		if(location.href.indexOf('localhost') > -1 || location.href.indexOf('-dev') >  -1)
		{
			return 'dev';
		} else if(location.href.indexOf('-staging') >  -1)
		{
			return 'staging';
		} else if(location.href.indexOf('-sandbox') >  -1)
		{
			return 'sandbox';
		} else {
			return 'production';
		}
	}
	
}