<template>
	<v-alert :type="type" :value="!!error">{{error}}</v-alert>
</template>

<script>
export default {

	methods: {
		show(error, type = 'error')
		{
			this.type = type;
			this.error = error;
			clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this.error = null;
			}, 5000);
		}
	},

	data: () => ({
		visible: false,
		type: 'error',
		error: null,
		timeout: null
	}),
}
</script>