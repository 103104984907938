<template>
	<v-container fluid class="pa-8 text-left" v-resize="onResize">
		<v-card>
			<v-card-title>Logs</v-card-title>
			<v-card-text>
				<v-row align="center">
					<v-col cols="4">
						<v-radio-group v-model="live" row>
							<v-radio :value="false" label="By Date"/>
							<v-radio :value="true" label="Live"/>
						</v-radio-group>
					</v-col>
					<v-col v-show="!live" cols="3" class="log-dates">
						<v-datetime-picker  v-model="start" label="Start">
							<template v-slot:dateIcon><v-icon>mdi-calendar</v-icon></template>
							<template v-slot:timeIcon><v-icon>mdi-clock</v-icon></template>
						</v-datetime-picker>
					</v-col>
					<v-col v-show="!live" cols="3" class="log-dates">
						<v-datetime-picker v-model="end" label="End">
							<template v-slot:dateIcon><v-icon>mdi-calendar</v-icon></template>
							<template v-slot:timeIcon><v-icon>mdi-clock</v-icon></template>
						</v-datetime-picker>
					</v-col>
					<v-col v-show="!live" cols="2">
						<v-btn text color="primary" @click="load">
							Reload
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="pa-3">
					<LogViewer :log="log" />
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

import {mapState} from 'vuex';

import Vue from 'vue';

import LogViewer from '@femessage/log-viewer';
import DatetimePicker from 'vuetify-datetime-picker';

Vue.use(DatetimePicker)

export default {
	name: "Logs",

	components: {
		LogViewer
	},

	computed: {
		...mapState('projects', [ 'item', 'loaded' ]),
		...mapState('logs', [ 'log' ]),
		height()
		{
			return this.windowSize.height - 300;
		}

	},
	mounted () {
      	this.onResize();
		this.load();
    },
	methods:{
		onResize()
		{
			this.windowSize = { width: window.innerWidth, height: window.innerHeight }
		},
		async load()
		{
			if(this.item.integrationId)
			{
				const { live, start, end } = this; 
				const { integrationId } = this.item;
				await this.$store.dispatch('logs/load', { live, start, end, integrationId });
			}
			
		}
	},

	watch: {
		live(value)
		{
			this.load();
		},
		item()
		{
			this.load();
		}
	},

	data: () => {

		const start = new Date();
		const end = new Date();

		start.setHours(start.getHours() - 1);

		return {
			live: false,
			windowSize: { width: 0, height: 0},
			start,
			end
		}
	},
};
</script>

<style>

.log-viewer .line-number {
	display: none;
}
.log-viewer .line-content {
	font-family: monospace;
}

.log-viewer {
	width: 100%;
}

.log-dates .v-text-field__details {
	display: none;
}
.log-dates .v-input__slot {
	margin-bottom: 0;
}


</style>
