<template>
	<v-container fluid class="pa-5">
		<v-row v-if="list.length == 0 && loaded"> 
			<v-col cols="12">
				No integrations created. Click the <v-icon small color="primary">mdi-plus-circle</v-icon> button to get started.
			</v-col>
		</v-row>
		<v-row dense class="text-left">
			<v-col v-for="item in list" :key="item.integrationId" :cols="12" :sm="6" :md="4" :lg="3">
				<Card :item="item"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {mapState} from 'vuex';

import Card from '@/components/developer/Card';

export default {
	name: "Integrations",

	components: {
		Card
	},

	computed: {
		...mapState('projects', ['list', 'loaded'])
	},

	created () {
		this.$store.dispatch('projects/load')
	},

	data: () => ({
	}),
};
</script>
