<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Remove Rule</v-card-title>
		<v-card-text class="text-left">
			<v-row>
				Are you sure you want to remove this rule?
			</v-row>
			<v-row>
				Users will no longer be automatically matched.
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text @click="save">Remove</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "RemoveRule",

	props: {
		item: Object,
		rule: Object,
		type: String
	},

	components: {
		DialogError
	},

	methods: {
		async save()
		{
			try {
				await this.$store.dispatch('rules/removeRule', {
					sourceIntegrationId: this.item.integrationId,
					sourceField: this.rule.sourceField,
					targetIntegrationId: this.rule.target.integrationId,
					targetField: this.rule.targetField,
					type: this.type 
				});
			} catch(e) {
				// console.error(e);
				this.$refs.error.show('Could unlink user. Please try again later.');
			}

			this.close();

		},
		close()
		{
			this.$emit('close');
		}
	},

	data: () => ({
	}),
};
</script>
