export default {
	get(item)
	{
		const { properties } = item;
		if(properties.firstName && properties.lastName)
		{
			return `${properties.firstName} ${properties.lastName}`;
		} else if(properties.email) {
			return properties.email
		} else if(properties.name) {
			return properties.name
		}
	}
}