<template>
	<v-card>
		<v-card-title align="left">
			<v-row>
				<v-col v-if="item.logo" cols="12">
					<v-img :src="item.logo" height="40" contain position="left center"></v-img>
				</v-col>
				<v-col v-else cols="12">
					<div class="logo-placeholder"/>
				</v-col>
				<v-col cols="12">
					{{item.name}}
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" class="body-2">
					<div class="project-card-text">
						{{item.description}}
					</div>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn @click="view"  text color="primary">View</v-btn>
		</v-card-actions>
	</v-card>
</template>


<script>

export default {
	name: "Projects",

	props: {
		item: Object
	},

	methods: {
		view()
		{
			this.$router.push({ name: 'Details', params: { id: this.item.integrationId } });
		}
	},

	data: () => ({
	}),
};
</script>

<style>
.logo-placeholder {
	height: 40px;
}
.project-card-text {
	white-space: nowrap !important;
	word-break: normal!important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
</style>