<template>
	<v-card flat fluid>
		<DialogError ref="error"/>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-jsf v-model="values" :schema="schema" :options="options">
					<template slot="custom-optional-object" slot-scope="props">
						<v-checkbox v-model="visible[props.fullKey]" :label="`Include optional ${props.modelKey}`"/>
						<v-jsf v-if="visible[props.fullKey]" v-model="nestedValues[props.fullKey]" v-bind="props"/>
					</template>
				</v-jsf>
			</v-form>
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>

		<v-card-actions>
			<v-btn color="primary" text @click="mock">Generate Data</v-btn>
			<v-spacer/>
			<v-btn :disabled="!valid || loading" color="primary" text @click="send">Send</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>

import DialogError from '@/components/util/DialogError';
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'

import _ from 'lodash';
import faker from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { mapGetters } from 'vuex';

import config from '@/api/config';

export default {
	name: "Inject",

	props: {
		item: Object,
		selected: Object
	},

	components: {
		VJsf,
		DialogError
	},

	computed: {
		...mapGetters('user', ['organizationId']),
		schema()
		{
			const schema = _.cloneDeep(this.selected.schema);

			//auto populate schema headers
			if(schema.properties.headers)
			{
				const { headers } = schema.properties;
				headers.default = {
					id: uuidv4(),
					sender: uuidv4(),
					dateTime: new Date().toISOString()
				};

				_.each(headers.properties, (property, i)=>{
					property.readOnly = true;
				});
			}

			this.setEntity(schema.properties, schema.required);

			return schema;

		}
	},

	methods: {
		setEntity(properties, required = [], base)
		{
			_.each(properties, (property, i)=>{
				if(property.type === 'object')
				{
					const name = (base) ? `${base}.${i}` : i;
					if(required.indexOf(i) === -1)
					{
						property['x-display'] = "custom-optional-object";
						this.$set(this.visible, name, true);
					}
					
					this.setEntity(property.properties, property.required, name);
				}
			});
		},
		getConfig(i, schema)
		{
			return {
				displayName: i,
				schema
			}
		},
		mock()
		{
			const { schema, mock } = this.selected;
			this.mockProperties(schema.properties, mock);
			
		},

		mockProperties(properties, mocks, base = null)
		{
			_.each(properties, (property, i)=>{

				if(property.type === 'object')
				{
					this.mockProperties(property.properties, mocks, i);
				} else {
					
					const key = (base) ? `${base}.${i}`: i;
					const mock = _.get(mocks, key);

					let fn, args;

					if(typeof mock === 'object')
					{
						fn = _.get(faker, mock.fn);
						args = mock.args || [];
					} else {
						fn = _.get(faker, mock);
						args = [];
					}

					if(fn && typeof fn === 'function')
					{
						let value = fn.apply(this, args);

						if(value instanceof Date)
						{
							var iso = value.toISOString();
							value = iso.substring(0, iso.indexOf('T'));
						}

						const obj = (base) ? _.get(this.values, base) : this.values;
						this.$set(obj, i, value);
																		
					}
				}
			})
		},
		async send()
		{
			this.loading = true;
			const values = _.cloneDeep(this.values);

			//restore nested optional objects
			_.each(this.visible, (value, key)=>{
				if(value)
				{
					_.set(values, key, this.nestedValues[key]);
				} else {
					_.set(values, key, undefined);
				}
			});

			try {
				await config.inject(this.selected.cls, values);
			} catch(e) {
				//console.error(e);
				this.$refs.error.show(`Could not inject test message to integration.`);
			}

			this.loading = false;

		},
		reset()
		{
			this.$set(this.values, {});
		}
	},

	data: () => ({
		loading: false,
		values: {},
		valid: false,
		visible: {},
		nestedValues: [],
		options: {
			initialValidation: 'all',
			rootDisplay: 'expansion-panels',
			idPrefix: 'inject-'
		}
	}),
};
</script>
