<template>
	<v-container fluid class="pa-5">
		<v-row class="text-center">
			<v-col cols="12">
				<Stats/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Stats from '@/components/home/Stats';

export default {
	name: "Home",

	components: {
		Stats
	},

	data: () => ({
	}),
};
</script>
