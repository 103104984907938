<template>
	<v-container fluid class="pa-5 text-left">
		<v-card flat>
    		<v-card-title>
				{{title}} 
				<v-spacer/>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-card-text v-if="loaded">
				<v-data-table
					:headers="columns"
					:items="entities"
					:items-per-page="(entities.length < 15) ? -1 : 15"
					class="elevation-1"
					:search="search"
				>
					<template v-slot:item.integrations="{ item }">
						<div v-for="(integration, i) in item.integrations" :key="i" class="entityTableIcon">
							<v-menu open-on-hover bottom offset-y>
      							<template v-slot:activator="{ on, attrs }">
									<v-img :src="getIcon(i)" :height="24" :width="24" v-on="on" />
								  </template>
								  <CandidateInfo :candidate="integration" :title="getName(i)"/>
    						</v-menu>						
						</div>
					</template>
					<template v-slot:item.externalKey="{ item }">
						<div>
							{{getExternalKey(item)}}
						</div>
					</template>
					<template v-slot:item.properties="{ item }">
						<v-menu open-on-hover bottom offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-icon small v-on="on">mdi-information</v-icon>
							</template>
							<CandidateInfo :candidate="item" title="All Properties"/>
						</v-menu>	
					</template>
					<template v-slot:item.archived="{ item }">
						<v-menu open-on-hover bottom offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-icon small v-on="on">mdi-information</v-icon>
							</template>
							<StatusInfo :status="item.archived" title="Status"/>
						</v-menu>	
					</template>
				</v-data-table>

			</v-card-text>
		</v-card>
	</v-container>
	
</template>

<script>

import { mapState } from 'vuex';
import _ from 'lodash';
import CandidateInfo from '@/components/users/CandidateInfo';
import StatusInfo from '@/components/users/StatusInfo';


export default {
	name: "Details",
	props: {
		type: String,
		item: Object,
		title: String,
		columns: Array
	},
	components: {
		CandidateInfo,
		StatusInfo
	},

	computed: {
		...mapState('entities', ['entities', 'loaded']),
		...mapState('configuration', ['list'])
	},

	methods: {
		getIcon(integrationId)
		{
			const integration = _.find(this.list, ['integrationId', integrationId]);
			if(integration)
			{
				return integration.icon;
			}
		},
		getName(integrationId)
		{
			const integration = _.find(this.list, ['integrationId', integrationId]);
			if(integration)
			{
				return integration.name;
			}
		},
		getExternalKey(item)
		{
			const integrationId = (this.item) ? this.item.integrationId : null;
			if(item && item.integrations && item.integrations[integrationId])
			{
				return item.integrations[integrationId].externalKey;
			}
		},
		load()
		{
			if(this.type || this.item)
			{
				const integrationId = (this.item) ? this.item.integrationId : null;
				this.$store.dispatch('entities/load', { type: this.type, integrationId });
				this.$store.dispatch('configuration/load');
			}
			
		}
	},

	watch: {
		item()
		{
			this.load();
		},
		type()
		{
			this.load();
		}
	},

	created () {
		
		this.load();
		
	},



	data: () => ({
		search: ''
	}),
};
</script>
<style>
.entityTableIcon {
	display: inline-block;
	margin-right: 5px;
}
</style>