<template>
	<v-form ref="form" v-model="valid">
		<v-row class="text-left">
			<v-col class="font-weight-black" cols="12" md="3">Name</v-col>
			<v-col cols="12" md="9">
				<v-text-field v-model="name" outlined :rules="nameRules" :counter="36" label="Name" required ></v-text-field>
			</v-col>
		</v-row>
		<v-row class="text-left">
			<v-col class="font-weight-black" cols="12" md="3">Description</v-col>
			<v-col cols="12" md="9">
				<v-textarea v-model="description" outlined :rules="descriptionRules" :counter="1024" label="Description" required ></v-textarea>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>

import _ from 'lodash';

export default {
	name: "IntegrationDetailsForm",

	props: {
		value: Object
	},

	watch: {
		valid(value)
		{
			this.$emit('valid', value);
		}
	},

	computed: {
		name: {
			get()
			{
				return this.value.name || '';
			},
			set(value)
			{

				this.$emit('input', { ...this.value, name: value});
			}
			
		},

		description: {
			get()
			{
				return this.value.description || '';
			},
			set(value)
			{

				this.$emit('input', { ...this.value, description: value});
			}
			
		},
	},

	methods: {
		reset()
		{
			this.$refs.form.resetValidation();
		}
	},

	data: () => ({
		valid: false,
		nameRules: [
			v => !!v || 'Name is required',
			v => v.length <= 36 || 'Name must be less than 36 characters'
		],
		descriptionRules: [
			v => !!v || 'Description is required',
			v => v.length <= 1024 || 'Name must be less than 1024 characters'
		]
	}),
};
</script>
