<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Install Integration</v-card-title>
		<v-card-text class="text-left">
			<v-list two-line>
				<v-list-item-group v-model="model" color="primary" >
					<v-virtual-scroll :height="500" :item-height="100" :items="list">
						<template v-slot="{ item }">
							<v-subheader v-if="item.header" inset class="text-overline integration-list-item">
								{{item.name}}
							</v-subheader>
							<v-list-item v-else :disabled="item.installed || !item.implementation" class="integration-list-item" @click="select(item)">
								<v-list-item-avatar>
									<v-img :src="item.icon"></v-img>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-col cols="9">
										<v-list-item-title v-html="item.name"></v-list-item-title>
										<v-list-item-subtitle v-html="item.description"></v-list-item-subtitle>
									</v-col>
									<v-col cols="3">
										<span v-if="item.installed">Installed</span>
										<span v-else-if="!item.implementation"><span class="text-overline grey--text">Unavailable</span><br>Requires Upload</span>
									</v-col>
								</v-list-item-content>
							</v-list-item>
							<v-divider></v-divider>
						</template>
					</v-virtual-scroll>
				</v-list-item-group>
			</v-list>
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>
		<v-card-actions>
			<v-btn text :disabled="loading" @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text :disabled="!valid || loading" @click="save">Install</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';
import { mapGetters } from 'vuex';

export default {
	name: "AddIntegration",

	components: {
		DialogError
	},

	computed: {
		...mapGetters('integrations', [ 'groupedIntegrations' ]),
		list()
		{
			const items = [];

			_.each(this.groupedIntegrations, (integrations, group)=>{
				items.push({ name: group, header: true});
				items.push(...integrations);
			});

			return items;
		},
		valid()
		{
			return this.selected !== null;
		}
	},

	created () {
		this.$store.dispatch('integrations/load')
	},

	methods: {
		async save()
		{
			this.loading = true;
			try {
				await this.$store.dispatch('configuration/installIntegration', {
					integrationId: this.selected.integrationId,
					version: this.selected.version,
					build: this.selected.build
				});
			} catch(e) {
				console.error(e);
				this.$refs.error.show('Could not install integration. Please try again later.');
				this.loading = false;
				return;
			}

			this.close();

		},
		select(item)
		{
			this.selected = item;
		},
		close()
		{
			this.loading = false;
			this.selected = this.model = null;
			this.$emit('close');
		}
	},

	data: () => ({
		loading: false,
		model: null,
		selected: null
	}),
};
</script>
<style>
.integration-list-item {
	height: 100px;
}
</style>