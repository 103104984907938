<template>
	<v-container fluid class="pa-8 text-left">
		<v-card>
			<DialogError ref="error"/>
			<v-card-title>
				Details
				<v-btn absolute right icon @click="edit = true">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text v-if="!edit">
				<v-row class="text-left">
					<v-col class="font-weight-black" cols="12" md="3">Name</v-col>
					<v-col cols="12" md="9">{{item.name}}</v-col>
				</v-row>
				<v-row class="text-left">
					<v-col class="font-weight-black" cols="12" md="3">Description</v-col>
					<v-col cols="12" md="9">{{item.description}}</v-col>
				</v-row>
			</v-card-text>
			<v-card-text v-if="edit">
				
				<IntegrationDetailsForm ref="form" v-model="details" @valid="validate" />
			</v-card-text>
			<v-card-actions v-if="edit">
				<v-btn text @click="close">
					Cancel
				</v-btn>
				<v-spacer/>
				<v-btn text :disabled="!valid" color="primary" @click="save">
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-card class="mt-8">
			<v-card-title>Images</v-card-title>
			<v-card-text>
				<v-row class="text-left align-center">
					<v-col class="font-weight-black" cols="12" md="3">
						Icon
					</v-col>
					<v-col cols="12" md="4">
						{{sizes.icon.width}} x {{sizes.icon.height}} transparent PNG required.
					</v-col>
					<v-col cols="12" md="3">
						<v-img v-if="item.icon" :src="item.icon" class="mx-auto"  :width="sizes.icon.width * sizes.icon.preview" :height="sizes.icon.height * sizes.icon.preview" />
					</v-col>
					<v-col cols="12" md="2">
						<v-dialog v-model="showIcon" :max-width="1150" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn x-small text color="primary" v-bind="attrs" v-on="on" >
									Upload
								</v-btn>
							</template>
							<ImageUpload :item="item" type="icon" :width="sizes.icon.width" :height="sizes.icon.height" @close="showIcon=false"/>
						</v-dialog>
					</v-col>
				</v-row>
				<v-row class="text-left align-center">
					<v-col class="font-weight-black" cols="12" md="3">
						Logo
					</v-col>
					<v-col cols="12" md="4">
						{{sizes.logo.width}} x {{sizes.logo.height}} transparent PNG required.
					</v-col>
					<v-col cols="12" md="3">
						<v-img v-if="item.logo" :src="item.logo" class="mx-auto" :width="sizes.logo.width * sizes.logo.preview" :height="sizes.logo.height * sizes.logo.preview" />
					</v-col>
					<v-col cols="12" md="2">
						<v-dialog v-model="showLogo" :max-width="1150" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn x-small text color="primary" v-bind="attrs" v-on="on" >
									Upload
								</v-btn>
							</template>
							<ImageUpload :item="item" type="logo" :width="sizes.logo.width" :height="sizes.logo.height" @close="showLogo=false"/>
						</v-dialog>
						
					</v-col>
				</v-row>
			</v-card-text>
			

		</v-card>
		<v-card class="mt-8">
			<v-card-title>Web Hooks</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						Web hooks can be created by any integration to receive calls from outside services.
						For more information on webhooks, visit <a :href="webhooks" target="blank">here</a>.
						<v-row>
							<v-col>
								The webhook base URL for this integration is:				
							</v-col>
						</v-row>
						<v-row>
							<v-col class="url">
								{{webhookUrl}}
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

import {mapGetters, mapState} from 'vuex';
import ImageUpload from '@/components/developer/ImageUpload';
import IntegrationDetailsForm from '@/components/developer/IntegrationDetailsForm';
import DialogError from '@/components/util/DialogError';

import md5 from 'md5';
import env from '@/api/env';

export default {
	name: "App",

	components: {
		ImageUpload,
		IntegrationDetailsForm,
		DialogError
	},

	computed: {
		...mapState('projects', [ 'item' ]),
		...mapGetters('user', ['organizationId']),
		details: {
			get()
			{
				return this.item;
			},
			set(value)
			{
				this.edits = value;
			}
		},
		webhookUrl()
		{
			const hash = md5(`${this.organizationId}-${this.item.integrationId}`);
			return `https://webhook-integration-${hash}.endpoints${env.suffix()}.fusionos.io`;
		}
	},

	methods: {
		async save()
		{
			const { name, description } = this.edits;
			const { integrationId } = this.item;
			try {
				await this.$store.dispatch('projects/updateProject', { integrationId, name, description });
			} catch(e) {
				//console.error(e);
				this.$refs.error.show('Could not update integration. Please try again later.');
				return;
			}

			this.close();
		},
		close()
		{
			this.$refs.form.reset();
			this.edits = {};
			this.edit = false;
		},
		validate(value)
		{
			this.valid = value;
		}
	},

	data: () => ({
		edit: false,
		edits: {},
		valid: false,
		showIcon: false,
		showLogo: false,
		sizes: {
			logo: {
				width: 768,
				height: 256,
				preview: .25
			},
			icon: {
				width: 512,
				height: 512,
				preview: .15
			}
		},
		nameRules: [
			v => !!v || 'Name is required',
			v => v.length <= 36 || 'Name must be less than 36 characters'
		],
		descriptionRules: [
			v => !!v || 'Description is required',
			v => v.length <= 1024 || 'Name must be less than 1024 characters'
		],
		webhooks: "https://developer.fusionos.io/?docs=going-further"
	}),
};

</script>

