<template>
	<v-container fluid class="pa-8">
		<div v-if="loaded">
			<EntitiesList title="Known Entities" :columns="columns" :item="item"/>
		</div>
	</v-container>
</template>

<script>

import store from '@/store/index'
import {mapState} from 'vuex';
import _ from 'lodash';
import EntitiesList from '@/components/util/EntitiesList';


export default {
	name: "Entities",

	computed: {
		...mapState('projects', [ 'item', 'loaded' ]),
	},
	components: {
		EntitiesList
	},

	data: () => ({
		columns: [
			{ text: 'Entity Id', value: 'entityId' },
			{ text: 'Type', value: 'type' },
			{ text: 'External Key', value: 'externalKey' },
			{ text: 'Integrations', value: 'integrations' },
			{ text: 'Properties', value: 'properties' },
			{ text: 'Status', value: 'archived' }
		]
	}),
};
</script>
