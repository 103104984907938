<template>
	<v-container fluid class="sidebar pa-5 align-baseline" fill-height align-content-start>
		<v-row>
			<v-col cols="10" class="text-center text-overline">
				Event Types 
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-list class="transparent">

					<v-list-group v-for="(schema, i) in schemas" :key="i" :value="true">
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>{{schema.name}}</v-list-item-title>
							</v-list-item-content>
						</template>

						<v-list-item v-for="(item, j) in schema.items" :key="j" link @click="select(item)">
							<v-list-item-content>
								<v-list-item-title v-text="item.name"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>


import {mapGetters} from 'vuex';

export default {
	name: "EventSide",

	computed: {
		...mapGetters('configuration', ['schemas']),
		
	},

	methods: {
		select(value)
		{
			this.$emit('select', value);
		}
	},
	

	data: () => ({
	}),
};
</script>
