<template>
	<v-container>
		<v-row>
			<v-col>
				First, let's select a type of data from {{item.name}} that we want to send to another integration.
			</v-col>	
		</v-row>
		<v-row>
			<v-col>
				<v-select v-model="value.input" outlined label="Source" :items="item.implementation.inputs" item-text="name" return-object>
				</v-select>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "ConnectionsSource",

	props: {
		item: Object,
		value: Object
	},

	computed: {},
	methods: {
		
	},

	data: () => ({
	}),
};
</script>
