import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	rules: [],
	loaded: false
});

const getters = {
	groupedConnections(state)
	{
		return _.groupBy(state.connections, 'group');
	},
	filtered(state)
	{
		//retrieve filtered list of either 'inputs' or 'outputs' by cls
		return (type, cls) => {
			return _.filter(state[type], (item)=> {
				if(_.find(item.implementation[type], [ 'cls', cls]))
				{
					return item;
				}
			});
		};
			
	},
	getRulesByIntegration(state)
	{
		return (integrationId, type) => {
			return _.filter(state.rules, ['sourceIntegrationId', integrationId, 'type', type]);
		};
			
	}
};


const mutations = {
	setRules: (state, rules) => {
		state.rules = rules;
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	}
};

const actions = {
	load: async ({commit}, type) => {
		
		const rules = await config.getRules(type);
		
		const installed = await config.getInstalledIntegrations();
		const inputs = _.filter(installed, (integration) => integration.implementation.inputs.length > 0);

		_.each(rules, (rule) => {

			rule.source = _.find(inputs, [ 'integrationId', rule.sourceIntegrationId ]);
			rule.target = _.find(inputs, [ 'integrationId', rule.targetIntegrationId ]);

		});

		commit('setRules', rules);
		commit('setLoaded', true);

	},

	async addRule({dispatch}, { sourceIntegrationId, sourceField, targetIntegrationId, targetField, type })
	{
		await config.addRule(sourceIntegrationId, sourceField, targetIntegrationId, targetField, type);
		await dispatch('load');
	},

	async removeRule({dispatch}, { sourceIntegrationId, sourceField, targetIntegrationId, targetField, type })
	{
		await config.removeRule(sourceIntegrationId, sourceField, targetIntegrationId, targetField, type);
		await dispatch('load');
	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};