import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: [],
	loaded: false
});


const getters = {
	groupedIntegrations(state)
	{
		return _.groupBy(state.list, 'group');
	},
};

const mutations = {
	setIntegrations: (state, integrations) => {
		state.list = integrations;
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
};

const actions = {
	load: async ({commit, rootGetters, rootState}) => {

		const integrations = await config.getAvailableIntegrations();
		const installed = rootState.configuration.list;

		const organizationId = rootGetters['user/organizationId'];

		_.each(integrations, (integration) => {


			const found = _.find(installed, [ 'integrationId', integration.integrationId]);
			integration.installed = !!found;

			if(integration.owner === organizationId)
			{
				integration.group = 'development';
			} else if(integration.private)
			{
				integration.group = 'private';
			} else if(integration.licensed)
			{
				integration.group = 'licensed';
			} else {
				integration.group = 'public';
			}
		});

		commit('setIntegrations', integrations);
		commit('setLoaded', true);
	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};