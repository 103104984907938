<template>
	<v-container>
		<v-row>
			<v-col>
				Finally, let's customize how will use the data.
			</v-col>	
		</v-row>
		<v-row>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "ConnectionsOptions",

	props: {
		item: Object,
		value: Object
	},

	computed: {},
	methods: {
		
	},

	data: () => ({
	}),
};
</script>
