<template>
	<v-container fluid class="sidebar pa-5 align-baseline" fill-height align-content-start>
		<v-row v-if="outputs.length > 0">
			<v-col class="text-center">
				Outputs 
			</v-col>
		</v-row>
		<v-row dense class="text-left">
			<v-col v-for="item in outputs" :key="item.integrationId" :cols="12" class="align-start">
				<IntegrationCard :item="item" type="output" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {mapState} from 'vuex';
import IntegrationCard from '@/components/configure/IntegrationCard';

export default {
	name: "Outputs",

	components: {
		IntegrationCard
	},
	computed: {
		...mapState('configuration', ['outputs'])
	},

	data: () => ({
	}),
};
</script>
