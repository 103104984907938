import config from '@/api/config';
import _ from 'lodash';

const LIMIT = 10000;
const LIMIT_CHOP = .1;

const state = () => ({
	log: "",
	lineCount: 0,
	integrationId: null,
	loaded: false,
	live: false,
	socket: null
});


const getters = {
};

const mutations = {
	reset(state)
	{
		state.log = "";
		state.lineCount = 0;
	},
	addLine(state, line)
	{
		state.log += line;
		state.lineCount++;

		if(state.lineCount > LIMIT)
		{
			const index = nthIndex(state.log, '\n', LIMIT * LIMIT_CHOP);
			if(index > 0)
			{
				state.log = state.log.substring(index);
			}

			state.lineCount = state.log.split('\n').length;
			
		}

	},
	setEntries: (state, entries) => {
		state.entries = entries
	},
	setLive(state, live)
	{

		if(live !== state.live)
		{
			//changed modes, clear logs
			this.commit('logs/reset');
		}

		state.live = live;

		if(state.socket) {
			state.socket.close();
			state.socket = null;
		}

		if(live && state.integrationId)
		{
			state.socket = config.getLogWebsocket(state.integrationId);
			state.socket.addEventListener('message', (msg) => {
				
				if(!state.live)
				{
					//user changed to historic logs while we were loading
					return;
				}
				
				this.commit('logs/addLine', msg.data);	
				
			});
			state.socket.addEventListener('error', (e) => {
				//console.error(e);
			});
			state.socket.addEventListener('close', (e) => {
				
				if(!state.live)
				{
					//user changed to historic logs while we were loading
					return;
				}

				this.commit('logs/addLine','[Log connection closed]\n');
				state.socket = null;
			});
			state.socket.addEventListener('open', (e) => {

				if(!state.live)
				{
					//user changed to historic logs while we were loading
					return;
				}

				this.commit('logs/addLine', '[Log connection opened]\n');
			});
		} 
	},
	setLogs: (state, logs) => {
		state.log = "";
		_.each(logs, (line)=>{
			let message = JSON.parse(line.message);
			state.log += message.log;
		});
	},

	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
	setIntegrationId: (state, integrationId) => {
		state.integrationId = integrationId;
	},
};

const actions = {
	load: async ({commit, state}, { integrationId, live, start, end }) => {

		commit('setIntegrationId', integrationId);
		commit('setLive', live);

		if(!live)
		{
			commit('reset');
			const logs = await config.getLogs(integrationId, start, end);

			if(state.live || state.integrationId !== integrationId)
			{
				//user changed to live logs or another integation while we were loading
				return;
			}

			commit('setLogs', logs);
		}
		
		commit('setLoaded', true);
	}
};

function nthIndex(str, pattern, number){
    
	var i = -1;
	var n = number;

    while (n-- && i++ < str.length) {
        i = str.indexOf(pattern, i);
        if (i < 0) break;
    }

    return i;
}


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};