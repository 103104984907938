<template>
	<v-container fluid class="pa-8">
		<v-row>
			<v-col cols="12">
				<Projects/>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" :max-width="1150" persistent>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab bottom right fixed small color="primary" @click="dialog=true">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>
			<NewProject @close="dialog=false"/>
		</v-dialog>
	</v-container>
</template>

<script>

import Projects from '@/components/developer/Projects';
import NewProject from '@/components/developer/NewProject';

export default {
	name: "Developer",

	components: {
		Projects,
		NewProject
	},

	data: () => ({
		dialog: false
	}),
};
</script>
