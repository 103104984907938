import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: [],
	inputs: [],
	outputs: [],
	connections: [],
	inputsVisible: null,
	outputsVisible: null,
	loaded: false
});


const getters = {
	groupedConnections(state)
	{
		const list = {}
		const groups = _.groupBy(state.connections, 'group');

		_.each(groups, (group, i)=>{
			list[i] = _.groupBy(group, 'label');
		});

		return list;
	},
	filtered(state)
	{
		//retrieve filtered list of either 'inputs' or 'outputs' by cls
		return (type, cls) => {
			return _.filter(state[type], (item)=> {

				if(!cls) {
					return item;
				}
				
				if(_.find(item.implementation[type], [ 'cls', cls]))
				{
					return item;
				}
			});
		};
			
	},
	getIntegration(state)
	{
		return (integrationId) => {
			return _.find(state.list, ['integrationId', integrationId]);
		};
			
	},

	schemas(state)
	{
		const schemas = {};

		_.each(state.inputs, (input) => {

			_.each(input.implementation.inputs, (item)=>{

				const { cls } = item;
				const parts = cls.split('.');
				const name = parts.pop();
				const domain = parts.pop();

				if(!schemas[domain])
				{
					schemas[domain] = {
						name: _.startCase(domain),
						items: {}
					}
				}


				schemas[domain].items[name] = {
					cls,
					domain: _.startCase(domain),
					name: _.startCase(name)
				};

			});


		});


		//sort alpha
		_.each(schemas, (schema)=>{
			schema.items = sortObject(schema.items);
		});
		
		return sortObject(schemas);

	}
};

const mutations = {
	setIntegrations: (state, integrations) => {
		state.list = integrations;
	},
	setInputs: (state, inputs) => {
		state.inputs = inputs;
	},
	setOutputs: (state, outputs) => {
		state.outputs = outputs;
	},
	setConnections: (state, connections) => {
		state.connections = connections;
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
	setInputsVisible: (state, value) => {
		state.inputsVisible = value;
	},
	setOutputsVisible: (state, value) => {
		state.outputsVisible = value;
	},
	setMatchingType: (state, { integrationId, type }) => {
		const input = _.find(state.inputs, [ 'integrationId', integrationId]);
		input.matchType = type;
	}
};

const actions = {
	async load({commit}){
		
		const installed = await config.getInstalledIntegrations();

		//split installed integrations into inputs and outputs
		let inputs = _.filter(installed, (integration) => integration.implementation.inputs.length > 0);
		const outputs = _.filter(installed, (integration) => integration.implementation.outputs.length > 0);

		const none = _.filter(installed, (integration) =>  integration.implementation.inputs.length === 0 && integration.implementation.outputs.length === 0);

		inputs = _.concat(inputs, none);
		

		//build array of all connections
		const connections = [];
		_.each(installed, (integration)=>{
			connections.push(...integration.connections);
		});

		_.each(connections, (connection) => {

			//set connections on inputs and outputs for convenience

			const input = _.find(inputs, [ 'integrationId', connection.fromIntegrationId ]);
			addConnection(input.implementation.inputs, connection.cls, connection.toIntegrationId);

			const output = _.find(outputs, [ 'integrationId', connection.toIntegrationId ]);
			addConnection(output.implementation.outputs, connection.cls, connection.fromIntegrationId);

			//add refs to inputs and outputs for connections and schemas

			connection.label = `${input.name} to ${output.name}`;

			connection.input = input;
			connection.output = output;
			connection.schemas = {
				input: _.find(input.implementation.inputs, ['cls', connection.cls ]),
				output: _.find(output.implementation.outputs, ['cls', connection.cls ]),
			};
			connection.group = _.startCase(connection.cls.split('.').reverse()[1]);

		});

		commit('setInputs', inputs);
		commit('setOutputs', outputs);
		commit('setConnections', connections);
		commit('setIntegrations', installed);
		commit('setLoaded', true);
	},

	inputsVisible({commit}, value)
	{
		commit('setInputsVisible', value);
	},

	outputsVisible({commit}, value)
	{
		commit('setOutputsVisible', value);
	},

	async setMatchingType({commit, dispatch }, { integrationId, type})
	{
		await config.setMatchingType(integrationId, type);
		await dispatch('rules/load', null, { root: true });
		commit('setMatchingType', { integrationId, type });
	},

	async installIntegration({dispatch}, { integrationId, version, build })
	{
		await config.installIntegration(integrationId, version, build);
		await dispatch('load');
	},

	async enableIntegration({dispatch}, { integrationId, settings, credentials })
	{
		await config.enableIntegration(integrationId, settings, credentials);
		await dispatch('load');
	},

	async disableIntegration({dispatch}, integrationId)
	{
		await config.disableIntegration(integrationId);
		await dispatch('load');
	},

	async upgradeIntegration({dispatch}, { integrationId, version, build })
	{
		await config.upgradeIntegration(integrationId, version, build);
		await dispatch('load');
		await dispatch('projects/load', null, { root: true });
	},

	async removeIntegration({dispatch}, integrationId)
	{
		await config.removeIntegration(integrationId);
		await dispatch('load');
	},

	async addConnection({dispatch}, { from, to, cls })
	{
		await config.addConnection(from, to, cls);
		await dispatch('load');
	},

	async deleteConnection({dispatch}, { from, to, cls })
	{
		await config.deleteConnection(from, to, cls);
		await dispatch('load');
	},

	async getSettings({dispatch}, integrationId)
	{
		return config.getSettings(integrationId);
	},

	async getStatus({dispatch}, integrationId)
	{
		return config.getStatus(integrationId);
	},

	async getCredentials({dispatch}, integrationId)
	{
		return config.getCredentials(integrationId);
	},


};

function sortObject(obj)
{
	const ordered = {};   
	_(obj).keys().sort().each(function (key) {	
		ordered[key] = obj[key];
	});
	return ordered;
}

function addConnection(schemas, cls, integrationId)
{
	let schema = _.find(schemas, ['cls', cls ]);
	schema.connections = schema.connections || [];
	schema.connections.push(integrationId);
}

export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};