
<template>
	<v-card>
		<v-btn fixed right icon fab @click="close">
			<v-icon>mdi-close</v-icon>
		</v-btn>
		<v-card-text align="left" class="pa-5">
			<SchemaInfoNode :schema="schema"/>
		</v-card-text>
	</v-card>
</template>

<script>

import _ from 'lodash';
import SchemaInfoNode from '@/components/util/SchemaInfoNode'


export default {
	name: "SchemaInfo",

	props: {
		schema: [Object, Array]
	},

	components: {
		SchemaInfoNode
	},

	methods: {
		close()
		{
			this.$emit('close');
		},
		getLabel(text)
		{
			return _.startCase(text);
		},
		truncateText(text, maxLength) {
			if (text.length > maxLength) {
				return text.slice(0, maxLength) + '...';
			}
			return text;
		}
	},

	data: () => ({
	}),
};
</script>
