import TWEEN from '@tweenjs/tween.js';
import _ from 'lodash';

const THREE = require('three');

const noop = function () { };

function moveAlong(object, shape, options) {
	options = _.merge({
		from: 0,
		to: 1,
		duration: null,
		speed: 50,
		start: true,
		repeat: false,
		yoyo: false,
		onStart: null,
		delay: 0,
		onComplete: noop,
		onUpdate: noop,
		smoothness: 100,
		easing: TWEEN.Easing.Linear.None
	}, options);

	// array of vectors to determine shape
	if (shape instanceof THREE.Shape || shape instanceof THREE.QuadraticBezierCurve3) {
		//do nothing
	} else if (shape.constructor === Array) {
		shape = new THREE.SplineCurve3(shape);

	} else {
		throw new Error('2nd argument is not a Shape, nor an array of vertices');
	}

	options.duration = options.duration || shape.getLength();
	options.length = options.duration * options.speed;

	var tween = new TWEEN.Tween({ distance: options.from })
		.to({ distance: options.to }, options.length)
		.easing(options.easing)
		.onStart(options.onStart)
		.onComplete(options.onComplete)
		.onUpdate(function (obj) {
			// get the position data half way along the path
			var pathPosition = shape.getPointAt(obj.distance);

			// move to that position
			object.position.set(pathPosition.x, pathPosition.y, pathPosition.z);
			if (options.onUpdate) { options.onUpdate(this, shape); }
		})
		.delay(options.delay)
		.yoyo(options.yoyo);

	if (options.yoyo) {
		tween.repeat(Infinity);
		tween.repeatDelay(0);
	}

	if (options.repeat) {
		tween.repeat(Infinity);
		tween.repeatDelay(0);
	}

	if (options.start) { tween.start(); }

	return tween;
}

export default moveAlong;