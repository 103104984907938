import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import env from '@/api/env';
import auth from '@/api/auth';
import moment from 'moment';

Vue.config.productionTip = false

async function main()
{
	await env.init();
	await auth.init();

	new Vue({
		vuetify,
		store,
		router,
		render: h => h(App)
	}).$mount('#app');

	Vue.filter('formatDatetime', function(value) {
		if (value) {
			return moment(String(value)).format('M/D/YY h:mm a')
		}
	});
}

main();