<template>
	<v-navigation-drawer v-if="visible" :mini-variant.sync="mini"  app permanent class="sidebar">

		<v-list-item class="logo">
			<v-list-item-avatar>
				<v-img :src="item.icon"></v-img>
			</v-list-item-avatar>

			<v-list-item-title>{{item.name}}</v-list-item-title>

			<v-btn icon @click.stop="collapse = !collapse">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
		</v-list-item>

		

		<v-divider></v-divider>

		<v-list nav dense>
			<v-list-item v-for="item in items" :key="item.title" link :to="item.path">
				<v-list-item-icon>
					<v-icon>{{item.icon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>{{item.title}}</v-list-item-title>
			</v-list-item>
		</v-list>

		

		<template v-slot:append>
			<v-list-item link class="grey lighten-2" @click="$router.push({ name: 'Projects'})" >
				<v-list-item-icon>
					<v-icon>mdi-arrow-left-circle</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Back</v-list-item-title>
			</v-list-item>
		</template>

	</v-navigation-drawer>

	
</template>

<script>

import {mapState} from 'vuex';

export default {
	name: "Sidebar",

	props: {
		visible: Boolean
	},

	computed: {
		...mapState('projects', [ 'item' ]),
		mini: {
			get()
			{
				//stay in mini on mobile
				return this.collapse || this.$vuetify.breakpoint.xsOnly;
			},
			set(value)
			{
				//ignore set in mobile
				if(!this.$vuetify.breakpoint.xsOnly)
				{
					this.collapse = value;
				}
			}
		}
	},

	data: () => ({
		drawer: true,
		collapse: false,
		items: [
			{
				title: 'Details',
				path: 'details',
				icon: 'mdi-information'
			},
			{
				title: 'Versions',
				path: 'versions',
				icon: 'mdi-cloud-upload'
			},
			{
				title: 'Test',
				path: 'test',
				icon: 'mdi-file-send'
			},{
				title: 'Logs',
				path: 'logs',
				icon: 'mdi-database-cog'
			},
			{
				title: 'Tools & Docs',
				path: 'tools',
				icon: 'mdi-toolbox'
			},
			{
				title: 'Known Entities',
				path: 'entities',
				icon: 'mdi-link'
			}
		]
	}),
};
</script>

<style scoped>
.logo {
	padding: 0 8px;
}
</style>