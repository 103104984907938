<template>
	<v-container fluid class="pa-0" fill-height align-content-start>
		<v-row v-if="$vuetify.breakpoint.mdAndUp" class="fill-height"> 
			<v-col cols="3" class="py-0">
				<Side @select="select"/>
			</v-col>
			<v-col cols="9" class="py-0">
				<Details :schema="selected"/>
			</v-col>
		</v-row>
		<v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-12"> 
			<v-col cols="12" class="text-overline">
				Configuration not available on small screen devices.
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>

import Side from '@/components/history/Side';
import Details from '@/components/history/Details';

export default {
	name: "Users",

	components: {
		Side,
		Details,
	},

	computed: {
		
	},
	
	methods: {
		select(value)
		{
			this.selected = value;
		}
	},

	created () {
		this.$store.dispatch('configuration/load');
	},

	data: () => ({
		selected: null
	}),
};
</script>
