<template>
	<v-container>
		<v-row>
			<v-col>
				Ok. Let's confirm the connection details before we save.
			</v-col>	
		</v-row>
		<v-row>
			<v-col>
				<v-list subheader three-line  color="grey lighten-5" width="100%" class="text-left">
					<v-list-item>
						<v-list-item-content>
							<ConnectionSummary :connection="connection" color="grey lighten-5"/>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-col>	
		</v-row>
		
		
		<v-row>
			<v-col v-if="isDisclosure">
				<v-divider class="my-5"/>
				<div class="title my-5">HIPAA Compliance</div>
				You are connecting an integration that may transmit PHI to another integration that is not HIPAA compliant. Please verify that this is allowed according to your HIPAA privacy policies. This may be permitted in situations such as:
				<ul class="my-5">
					<li>This is an authorized disclosure for which you have obtained consent from your residents</li>
					<li>You organization has determined it is covered under a permitted use or exception (45 CFR § 164.506)</li>
				</ul>
				By proceeding, you are instructing K4Connect, under the terms of our Business Associate Agreement, to make this disclosure on an ongoing basis.
				<v-checkbox v-model="value.hipaaConfirm" label="I agree"></v-checkbox>
				<v-text-field v-model="value.hipaaPurpose" label="Purpose of disclosure"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ConnectionSummary from '@/components/util/ConnectionSummary';

export default {
	name: "ConnectionsConfirm",

	components: {
		ConnectionSummary
	},

	props: {
		item: Object,
		value: Object,
		isDisclosure: Boolean
	},

	

	computed: {
		
		connection()
		{

			const { input, destination } = this.value;
			const { name } = this.item;
			return {
				schemas: {
					input: { name: input.name },
					output: { name: destination.schema.name }
				},
				input: { name },
				output: { name: destination.output.name }
			};
		}
	},

	data: () => ({
	}),
};
</script>
