import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: [],
	selected: null,
	loaded: false
});


const getters = {
	
};

const mutations = {
	setSchemas: (state, schemas) => {
		state.list = schemas.sort();
	},
	setSelected: (state, schema) => {
		state.selected = schema
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
};

const actions = {
	load: async ({commit, rootGetters}) => {

		const schemas = await config.getSchemas();

		commit('setSchemas', schemas);
		commit('setLoaded', true);
	},
	select: async ({commit, state, dispatch}, cls) => {
		
		const schema = await config.getSchema(cls);
		const mocks = await config.getMocks();
		const mock = mocks[cls] || {}

		commit('setSelected', { schema, mock, cls });
		
	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};