<template>
	<div>
		<v-card v-show="visible">
			<v-card-title align="left" :id="id">
				<v-row dense class="text-subtitle-1">
					<v-col cols="3">
						<v-img :src="item.icon" max-height="32" max-width="32" contain position="left center"></v-img>
					</v-col>
					<v-col cols="9" class="integration-card-expanded">
						<div>{{item.name}}</div>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-row dense justify="center" align="center">
					<v-col v-if="item.enabled" cols="8">Enabled</v-col>
					<v-col v-else cols="8">Installed</v-col> 
					<v-col cols="4">
						<v-dialog v-model="disable" :max-width="600" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-show="item.enabled" x-small text color="primary" v-bind="attrs" v-on="on" >
									Disable
								</v-btn>
							</template>
							<DisableIntegration :item="item" @close="disable=false"/>
						</v-dialog>
						<v-dialog v-model="enable" :max-width="1150" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-show="!item.enabled" x-small text color="primary" v-bind="attrs" v-on="on" >
									Enable
								</v-btn>
							</template>
							<EnableIntegration v-if="enable" :item="item" @close="enable=false"/>
						</v-dialog>
					</v-col>
				</v-row>
				<v-row v-if="item.enabled && schemas.length > 0" dense justify="center" align="center" class="mt-3">
					<v-col :cols="(type === 'input') ? 8 : 12" class="text-overline">
						Connections 
					</v-col>
					<v-col v-if="(type === 'input')" cols="4">
						<v-dialog v-model="dialog" :max-width="1150" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn x-small text color="primary" v-bind="attrs" v-on="on" >
									Add
								</v-btn>
							</template>
							<ConnectionDialog :item="item" @close="dialog=false"/>
						</v-dialog>
						
					</v-col>
				</v-row>
				<template v-if="item.enabled">
					<v-row v-for="(schema, i) in schemas" :key="schema.cls" dense :class="{ 'flex-row-reverse': (type === 'output') }">
						<v-col cols="9">
							{{schema.name}}
						</v-col>
						<v-col cols="3">
							<v-menu v-if="type === 'input' && schema.connections" v-model="menus[schema.cls]" bottom offset-y :close-on-content-click="false">
								<template v-slot:activator="{ on, attrs }">
									<v-btn x-small text plain v-bind="attrs" v-on="on">
										<ConnectionChip :item="item" :type="type" :schema="schema" />
									</v-btn>
								</template>
								<ConnectionInfo :item="item" :schema="schema" @close="menus[schema.cls]=false" />
							</v-menu>
							<ConnectionChip v-if="type === 'output' && schema.connections" :item="item" :type="type" :schema="schema" />
						</v-col>
					</v-row>
				</template>
				<v-row v-if="!item.enabled" dense justify="center" align="center">
					<v-col cols="8"></v-col> 
					<v-col cols="4">
						<v-dialog v-model="remove" :max-width="600" persistent>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-show="!item.enabled" x-small text color="primary" v-bind="attrs" v-on="on" >
									Remove
								</v-btn>
							</template>
							<RemoveIntegration :item="item" @close="remove=false"/>
						</v-dialog>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card v-show="!visible" height="50px" style="opacity: .40">
			<v-card-title align="left" class="py-0">
				<v-row>
					<v-col cols="3">
						<v-img :src="item.icon" max-height="25" max-width="100" contain position="left center"></v-img>
					</v-col>
					<v-col cols="9" class="text-subtitle-1 integration-card-collapsed">
						<div>{{item.name}}</div>
					</v-col>
				</v-row>
			</v-card-title>
		</v-card>
	</div>
</template>

<script>

import RemoveIntegration from '@/components/configure/RemoveIntegration';
import DisableIntegration from '@/components/configure/DisableIntegration';
import EnableIntegration from '@/components/configure/EnableIntegration';
import ConnectionDialog from '@/components/connections/ConnectionDialog';
import ConnectionInfo from '@/components/connections/ConnectionInfo';
import ConnectionChip from '@/components/connections/ConnectionChip';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
	name: "IntegrationCard",

	components: {
		RemoveIntegration,
		DisableIntegration,
		EnableIntegration,
		ConnectionDialog,
		ConnectionInfo,
		ConnectionChip
	},
	props: {
		type: String,
		item: Object
	},

	computed: {
		...mapState('configuration', ['inputsVisible', 'outputsVisible']),
		visible()
		{
			//show or hide depending on if connection lines are being drawn
			if(this.type === 'input')
			{
				return (!this.inputsVisible || this.inputsVisible.indexOf(this.item.integrationId) > -1)
			} else {
				return (!this.outputsVisible || this.outputsVisible.indexOf(this.item.integrationId) > -1)
			}
			
		},
		schemas()
		{
			return this.item.implementation[`${this.type}s`];
		},
		id()
		{
			return `${this.type}-${this.item.integrationId}`;
		}
	},
	

	data: () => ({
		dialog: false,
		enable: false,
		disable: false,
		remove: false,
		menus:{}
	}),
};
</script>

<style>
.integration-card-collapsed div {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.integration-card-expanded {
	word-break: normal;
}
</style>