<template>
	<v-container fluid class="pa-8 text-left">
		<v-card>
			<v-card-title>Tools</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>

					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3">
						FusionOS CLI
					</v-col>
					<v-col cols="9">
						The primary tool for uploading integrations to the FusionOS sandbox. 
						The CLI can be downloaded <a :href="cli" target="blank">here</a>.
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3">
						FusionOS SDK
					</v-col>
					<v-col cols="9">
						Java library used for all integrations. 
						The SDK is loaded as a dependency in the FusionOS environment, but can also be downloaded as a jar file for local development. 
						The SDK can be downloaded <a :href="sdk" target="blank">here</a>.
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card class="mt-8">
			<v-card-title>Documentation</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>

					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3">
						Developer Site
					</v-col>
					<v-col cols="9">
						The Developer Site contains all documentation, articles, and downloads for developing on FusionOS.
						The site is located at <a :href="docs" target="blank">{{docs}}</a>.
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>


export default {
	name: "Tools",


	data: () => ({
		cli: "https://developer.fusionos.io/?docs=download-the-cli",
		sdk: "https://developer.fusionos.io/?docs=fusionos-sdk-release-notes",
		docs: "https://developer.fusionos.io"
	}),
};
</script>

<style>
.url {
	font-family: monospace;
}
</style>