import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: []
});


const getters = {
};

const mutations = {
	setStats: (state, stats) => {
		state.list = stats;
	},
	
};

const actions = {
	load: async ({commit}) => {
		const stats = await config.getStats();
		commit('setStats', stats);

	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};