<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Remove {{item.name}}</v-card-title>
		<v-card-text class="text-left">
			<v-row class="error--text text-overline">
				Warning
			</v-row>
			<v-row>
				Are you sure you want to remove this integration?
			</v-row>
			<v-row>
				All settings and connections will be deleted. This operation cannot be undone.
			</v-row>
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>
		<v-card-actions>
			<v-btn text :disabled="loading" @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn :disabled="loading" color="primary" text @click="save">Remove</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "RemoveIntegration",

	props: {
		item: Object
	},

	components: {
		DialogError
	},

	methods: {
		async save()
		{
			this.loading = true;

			try {
				await this.$store.dispatch('configuration/removeIntegration', this.item.integrationId);
			} catch(e) {
				console.error(e);
				this.$refs.error.show('Could not remove integration. Please try again later.');
				this.loading = false;
				return;
			}

			this.close();

		},
		close()
		{
			this.loading = false;
			this.selected = null;
			this.$emit('close');
		}
	},

	data: () => ({
		loading: false
	}),
};
</script>