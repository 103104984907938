<template>
	<v-card :width="400">
		<v-card-title v-if="title">{{title}}</v-card-title>
		<v-card-text align="left">
			<v-row v-for="(value, i) in status" :key="i" dense>
				<v-col cols="4">	
					{{getLabel(i)}}
				</v-col>
				<v-col cols="8">
					<div>{{getValue(value)}}</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>

import _ from 'lodash';
import { mapState } from 'vuex';

export default {
	name: "StatusInfo",

	props: {
		status: Object,
		title: String
	},

	computed: {
		...mapState('configuration', ['list'])
	},

	methods: {
		getLabel(id)
		{
			const integration = _.find(this.list, ['integrationId', id]);
			return integration.name;
		},
		getValue(value)
		{
			
			return (value) ? 'Archived' : 'Active';
		}
	},

	data: () => ({
	}),
};
</script>
