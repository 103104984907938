<template>
	<v-card>
		<v-card-text>
			<v-row v-if="rules.length == 0 && loaded" class="text-center"> 
				<v-col cols="12">
					No rules created. Click the <v-icon small color="primary">mdi-plus-circle</v-icon> button to get started.
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" class="text-body-1">
					Automatically match {{typeName}} when:
				</v-col>
				<v-col cols="12">
					<v-list dense>
						<v-list-item v-for="rule in rules" :key="rule.id">
							<v-list-item-content>
								<v-row align="center" justify="center" dense>
									<v-col cols="4">
										<v-row align="center" dense>
											<v-col cols="3" class="text-right">
												<v-img class="inline-image" :max-height="30" contain :src="rule.source.icon"></v-img>
											</v-col>
											<v-col cols="9" class="text-left">
												{{rule.source.name}} <strong>"</strong>{{rule.sourceField}}<strong>"</strong>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="2" class="text-center">
										<v-icon large>mdi-equal</v-icon>
									</v-col>
									<v-col cols="4">
										<v-row align="center" dense>
											<v-col cols="3" class="text-right">
												<v-img class="inline-image" :max-height="30" contain :src="rule.target.icon"></v-img>
											</v-col>
											<v-col cols="9" class="text-left">
												{{rule.target.name}} <strong>"</strong>{{rule.targetField}}<strong>"</strong>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="2" class="text-center">
										<v-btn x-small text color="primary" @click="remove(rule)" >
											Remove
										</v-btn>
									</v-col>
								</v-row>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn large icon color="primary" @click="dialog = true">
				<v-icon>mdi-plus-circle</v-icon>
			</v-btn>
		</v-card-actions>
		<v-dialog v-model="showRemove" :max-width="600" persistent>
			<RemoveRule :item="item" :rule="selected" :type="type" @close="selected=null"/>
		</v-dialog>
		<v-dialog v-model="dialog" persistent :max-width="1150">
			<NewRule :item="item" :type="type" @close="close"/>
		</v-dialog>
	</v-card>	
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import NewRule from '@/components/users/NewRule';
import RemoveRule from '@/components/users/RemoveRule';

export default {
	name: "RulesCard",
	props: {
		item: Object,
		type: String
	},
	components: {
		NewRule,
		RemoveRule
	},
	computed: {
		...mapGetters('configuration', ['filtered']),
		...mapGetters('rules', ['getRulesByIntegration']),
		...mapState('rules', [ 'loaded' ]),
		list()
		{
			//get only input integrations that can produce users
			return this.filtered('inputs', 'io.fusionos.sdk.v1.census.Create');
		},
		rules()
		{
			return this.getRulesByIntegration(this.item.integrationId, this.type);
		},
		showRemove()
		{
			return !!this.selected;
		},
		typeName()
		{
			switch(this.type)
			{
				case 'CENSUS_LOCATION':
					return 'location';
				default:
					return 'user'

			}
		}
	},
	mounted()
	{
		this.reload();
	},

	watch: {
		item()
		{
			this.reload();
		},
		type()
		{
			this.reload();
		}
	},

	methods: {
		remove(rule)
		{
			this.selected = rule;
		},
		reload()
		{
			this.$store.dispatch('rules/load', this.type);
		},
		close()
		{
			this.dialog = false;
			this.reload();
		}
	},
	
	

	data: () => ({
		dialog: false,
		selected: null
	}),
};
</script>
