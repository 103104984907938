/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import router from '@/router/index';
import user from '@/store/modules/user'
import config from '@/api/config';
import env from '@/api/env';



async function init()
{

	const authData = {
		...env.auth(),
		TokenScopesArray : ['openid', 'email', 'profile'],
	};

	this.cognito = new CognitoAuth(authData);
	this.cognito.userhandler = userhandler;

}



const userhandler = {
	onSuccess: function(result) {

		console.log("On Success result", result);
		user.setLoggedIn(true);
		config.getUserInfo().then(response => {
			router.push('/');
		});
		
		
	},
	onFailure: function(err) {
		
		user.setLoggedOut();
		router.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
	}
};

function getUserInfoStorageKey(){
	const keyPrefix = 'CognitoIdentityServiceProvider.' + this.cognito.getClientId();
	const tokenUserName = this.cognito.signInUserSession.getAccessToken().getUsername();
	const userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
	return userInfoKey;
}

const storageHelper = new StorageHelper();
const storage = storageHelper.getStorage();

export default {
	init,
	login(){
		this.cognito.getSession();
	},
	logout(){
		if (this.cognito.isUserSignedIn()) {
			var userInfoKey = this.getUserInfoStorageKey();
			this.cognito.signOut();

			storage.removeItem(userInfoKey);
		}
	},
	getUserInfoStorageKey,

}