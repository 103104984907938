<template>
	<v-container fluid class="sidebar pa-5 align-baseline" fill-height align-content-start>
		<v-row v-if="list.length > 0">
			<v-col cols="10" class="text-center text-overline">
				Integrations 
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-list class="transparent">
					<v-list-item-group v-model="model" mandatory color="indigo">
						<v-list-item v-for="(item, i) in list" :key="i">
							<v-list-item-icon>
								<v-img :src="item.icon" max-height="25" max-width="25" contain position="left center"></v-img>
							</v-list-item-icon>

							<v-list-item-content class="text-left">
								<v-list-item-title v-text="item.name"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-5"/>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-account-multiple</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="text-left">
								<v-list-item-title>All Known</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>


import {mapGetters} from 'vuex';

export default {
	name: "Inputs",

	computed: {
		...mapGetters('configuration', ['filtered']),
		list()
		{
			//get only input integrations that can produce users
			return this.filtered('inputs');
		},
	},

	watch: {
		model(index)
		{
			this.$emit('select', this.list[index]);
		}
	},

	data: () => ({
		model: null
	}),
};
</script>
