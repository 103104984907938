<template>
	<v-select v-model="model" :label="label" :readonly="readonly" return-object outlined item-value="id" item-text="name" :items="items">
		<template v-slot:selection="{ item }">
			<img class="integration-selector-img" :src="item.icon" />{{ name(item) }}
		</template>
		<template v-slot:item="{ item }">
			<img class="integration-selector-img" :src="item.icon" />{{ item.name }}
		</template>
	</v-select>
</template>

<script>
export default {
	name: "IntegrationSelector",
	props: {
		items: Array,
		value: Object,
		readonly: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		model: {
			get()
			{
				return this.value;
			},
			set(value)
			{
				this.$emit('input', value);
			}
			
		},
		label()
		{
			return (this.readonly) ? '' : 'Select integration...';
		}
	},
	methods: {
		name(item)
		{
			return (item.name.length > 12) ? `${item.name.substring(0,12)}...` : item.name;
		}
	}
}

</script>

<style>

.integration-selector-img {
	max-height: 24px;
	object-fit: contain;
	margin-right: 20px;
}

</style>
