import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	entities: [],
	loaded: false
});

const getters = {};



const mutations = {
	setEntities: (state, entities) => {
		state.entities = entities;
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	}
};

const actions = {
	load: async ({commit}, filter) => {
		
		let entities;
		
		if(filter.integrationId)
		{
			entities = await config.getEntitiesByIntegration(filter.integrationId);
		} else if(filter.type){
			entities = await config.getEntitiesByType(filter.type);
		} else {
			return;
		}
		
		commit('setEntities', entities);
		commit('setLoaded', true);

	},

	sync: async ({commit}, integrationId) => {
		return config.sync(integrationId);
	}

};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};