<template>

<div id="app">
	<v-app>
		<v-app-bar v-if="!showSidebar && showHeader" app color="white" :clipped-left="!showSidebar">
			<v-spacer />
			<div class="nav">
				<router-link to="/">Home</router-link>
				<router-link to="/configure">Configure</router-link>
				<router-link to="/users">Users</router-link>
				<router-link to="/history">History</router-link>
				<router-link v-if="showDeveloper" to="/developer">Developer</router-link>
			</div>
			<v-spacer />
			<v-menu left bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn class="caption" text v-bind="attrs" v-on="on">
						{{ organizationName }}
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in organizations" :key="index" @click="orgChange(item)">
						<v-list-item-title>{{ item.name }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-menu left bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-menu</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item v-for="(item, index) in menu" :key="index" :to="item.route">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<Sidebar :visible="showSidebar"/>
		<v-main>
			<transition name="fade">
				<router-view :key="$route.fullPath"></router-view>
			</transition>
		</v-main>
		<Footer v-show="!showSidebar"/>
	</v-app>
</div>
</template>


<script>

import Sidebar from '@/components/developer/Sidebar'
import Footer from '@/components/util/Footer'

import { mapGetters, mapState } from 'vuex';
export default {

	components: {
		Sidebar,
		Footer
	},

	computed: {
		...mapState('user', [ 'cognitoInfo', 'organizations' ]),
		...mapGetters('user', [ 'environment', 'organizationId', 'organizationName']),
		showDeveloper()
		{
			return this.environment !== 'production';
		},
		showHeader()
		{
			let { path } = this.$route;
			return (path && path.indexOf('logout') === -1);
		},
		showSidebar()
		{
			//only show sidebar on developer app views
			let { path, params } = this.$route;
			return !!(path && path.indexOf('developer') >  -1 && params.id);
		}

	},
	methods: {
		orgChange(selected)
		{
			if(selected.organizationId !== this.organizationId)
			{
				this.$store.dispatch('user/select', selected);
			}
		}
	},
	data: () => ({
		menu: [
			{ title: 'HIPAA Audit',  route: '/hipaa' },
			{ title: 'Logout',  route: '/logout' }
		]
	}),

	
}
</script>

<style>
.v-app-bar {
	max-width: 100vw;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  padding: 30px;
}

.nav > a {
    color: #2c3e50 !important;
	margin: 0 20px;

    &.router-link-exact-active {
      color: #4c43db !important;
    }
	
}

.sidebar {
	background-color: #F6F6FA !important;
}
.transparent {
	background-color: transparent !important;
}

.v-list-item--link:before {
	background-color: #4c43db;
}

.v-list-item--link {
	color: #4c43db !important;
}

</style>
