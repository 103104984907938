<template>
	<v-container fluid class="pa-8 text-left">
		<v-card>
			<v-card-title>
				Versions
				<v-spacer/>
				<span class="overline">{{status}}</span>
			</v-card-title>
			<v-card-text>
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Version</th>
								<th class="text-left">Build</th>
								<th class="text-left">Created</th>
								<th class="text-left"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, i) in sorted" :key="i">
								<td>{{ row.version }}</td>
								<td>{{ row.build }}</td>
								<td>{{ new Date(row.created).toLocaleString() }}</td>
								<td>
									<v-dialog v-model="dialogs[i]" :max-width="600" persistent>
										<template v-slot:activator="{ on, attrs }">
											<v-btn x-small text color="primary" :disabled="!installed" v-bind="attrs" v-on="on" >
												Deploy
											</v-btn>
										</template>
										<DeployIntegration :item="item" :version="row.version" :build="row.build" @close="dialogs[i]=false"/>
									</v-dialog>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<v-row v-if="!installed" class="mt-5">
					<v-col>
						Integration must be installed on your account before you can deploy a version.
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import store from "@/store/index";
import { mapGetters, mapState } from "vuex";
import _ from 'lodash';
import DeployIntegration from '@/components/developer/DeployIntegration';

export default {
	name: "Versions",

	components: {
		DeployIntegration
	},

	computed: {
		...mapState("projects", ["item"]),
		...mapGetters("configuration", ["getIntegration"]),
		installed()
		{
			const integration = this.getIntegration(this.item.integrationId);
			return !!integration;
		},
		sorted()
		{
			return _.orderBy(this.item.versions, [ 'version', 'build' ], [ 'desc', 'desc' ]);
		}
	},

	async mounted()
	{
		await this.$store.dispatch('configuration/load');
		this.start();
	},

	beforeRouteLeave(to, from, next) {
		this.stop();
		next();
	},

	methods: {


		start()
		{
			this.poll();

			this.interval = setInterval(()=>{
				this.poll();
			}, 10000);
		},

		stop()
		{
			clearInterval(this.interval);
		},

		async poll()
		{

			const { integrationId } = this.item;

			try {
				const data = await this.$store.dispatch('configuration/getStatus', integrationId);
				this.status = data.state;

			} catch(e) {
				this.$refs.error.show('Could not get integration status.');
			}

		}
	},

	data: () => ({
		dialogs: {},
		status: null
	}),
};
</script>
