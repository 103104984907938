<template>
	<v-card>
		<DialogError ref="error"/>
		<v-card-text>
			<input
				class="hidden"
				ref="input"
				type="file"
				name="image"
				accept="image/*"
				@change="setImage"
			/>
			<v-row>
				<v-col cols="8">
					<v-row v-if="imgSrc">
						<vue-cropper
							
							ref="cropper"
							:aspect-ratio="aspect"
							:src="imgSrc"
							preview=".preview"
						/>
					</v-row>
					<v-row v-if="imgSrc">
						<v-col cols="1">
							<v-btn small icon @click="zoom(0.2)">
								<v-icon>mdi-magnify-plus</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="zoom(-0.2)">
								<v-icon>mdi-magnify-minus</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="move(-10, 0)">
								<v-icon>mdi-arrow-left-bold</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="move(10, 0)">
								<v-icon>mdi-arrow-right-bold</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="move(0, -10)">
								<v-icon>mdi-arrow-up-bold</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="move(0, 10)">
								<v-icon>mdi-arrow-down-bold</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="rotate(90)">
								<v-icon>mdi-rotate-right-variant</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon @click="rotate(-90)">
								<v-icon>mdi-rotate-left-variant</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon ref="flipX" @click="flipX">
								<v-icon>mdi-flip-horizontal</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="1">
							<v-btn small icon ref="flipY" @click="flipY">
								<v-icon>mdi-flip-vertical</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					
				</v-col>
				<v-col v-if="imgSrc" cols="4">
					<v-row class="pa-5">
						Preview
					</v-row>
					<v-row class="pa-5">
						<div class="preview" />
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="!imgSrc">
				<v-col cols="12">
					<div class="uploadPlaceholder justify-center align-center d-flex">
						<v-icon x-large>mdi-file-upload</v-icon>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">
				Cancel
			</v-btn>
			<v-spacer/>
			<v-btn text color="primary" @click="showFileChooser">
				{{imgSrc ? 'Replace Image' : 'Upload Image'}}
			</v-btn>
			<v-spacer/>
			<v-btn text :disabled="!imgSrc" color="primary" @click="save">
				Save
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import DialogError from '@/components/util/DialogError';

export default {
	name: "ImageUpload",
	props: {
		item: Object,
		type: String,
		width: Number,
		height: Number,
	},

	components: {
		VueCropper,
		DialogError
	},
	computed:{
		aspect()
		{
			return this.width / this.height;
		}
	},
	data() {
		return {
			imgSrc: null,
			cropImg: "",
			data: null,
		};
	},
	methods: {
		async save() {
			this.$refs.cropper.getCroppedCanvas({
				width: this.width,
				height: this.height
			}).toBlob(async (data)=>{
				try {
					await this.$store.dispatch('projects/uploadImage', {
						integrationId: this.item.integrationId,
						type: this.type,
						data
					});
					this.close();
				} catch(e) {
					console.error(e);
					this.$refs.error.show(`Could not upload ${this.type}. Please try again later.`);
				}
			});
		},
		close()
		{
			if(this.$refs.cropper)
			{
				this.$refs.cropper.reset();
			}
			this.imgSrc = null;
			this.$emit('close');
		},
		flipX() {
			const dom = this.$refs.flipX.$el;
			let scale = dom.getAttribute("data-scale");
			scale = scale ? -scale : -1;
			this.$refs.cropper.scaleX(scale);
			dom.setAttribute("data-scale", scale);
		},
		flipY() {
			const dom = this.$refs.flipY.$el;
			let scale = dom.getAttribute("data-scale");
			scale = scale ? -scale : -1;
			this.$refs.cropper.scaleY(scale);
			dom.setAttribute("data-scale", scale);
		},
		move(offsetX, offsetY) {
			this.$refs.cropper.move(offsetX, offsetY);
		},
		reset() {
			this.$refs.cropper.reset();
		},
		rotate(deg) {
			this.$refs.cropper.rotate(deg);
		},
		setImage(e) {
			const file = e.target.files[0];

			if(!file)
			{
				return;
			}

			if (file.type.indexOf("image/") === -1) {
				this.$refs.error.show('Please select an image file.');
				return;
			}

			if (typeof FileReader === "function") {
				const reader = new FileReader();

				reader.onload = (event) => {
					this.imgSrc = event.target.result;
					this.$nextTick(()=>{
						this.$refs.cropper.replace(event.target.result);
					})					
				};

				reader.readAsDataURL(file);
			} else {
				alert("Sorry, FileReader API not supported");
			}
		},
		showFileChooser() {
			this.$refs.input.click();
		},
		zoom(percent) {
			this.$refs.cropper.relativeZoom(percent);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


.hidden {
	display: none;
}

.preview {
	width: 100%;
	height: calc(372px * (9 / 16));
	overflow: hidden;
	border: 1px solid #ccc;
}

.uploadPlaceholder {
	background-color: #f2f2f2;
	height: 200px;
	margin-left: 25%;
	width: 50%;
}

</style>
