<template>
	<div>
		<DialogError ref="error"/>
		<v-card-title align="left">
		</v-card-title>
		<v-card-text>
			<v-row class="text-body-1" align="center" justify="center">
				<v-col cols="6">
					Current unmatched {{typeName}}s for {{item.name}}:
				</v-col>
				<v-col class="d-flex" cols="6">
					<v-spacer />
					<v-checkbox v-model="showArchived" label="Show Archived Users"/>
				</v-col>
			</v-row>
			<v-row v-if="candidates.length == 0 && loaded" class="text-center"> 
				<v-col cols="12">
					No pending manual matches found. Check back later.
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<v-virtual-scroll :items="candidates" :height="height" :item-height="itemHeight" >
						<template v-slot:default="{ item: candidate }">
							<v-list-item :key="candidate.externalKey">
								<v-list-item-content>
									<v-row dense class="align-center">
										<v-col cols="4">
											{{getLabel(candidate)}}
										</v-col>
										<v-col cols="2">
											<v-menu>
												<template v-slot:activator="{ on, attrs }">
													<v-btn x-small text icon v-bind="attrs" v-on="on">
														<v-icon>mdi-information</v-icon>
													</v-btn>
												</template>
												<CandidateInfo :candidate="candidate"/>
											</v-menu>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon v-if="candidate.archived" class="ml-5" v-bind="attrs" v-on="on">mdi-trash-can</v-icon>
												</template>
												<span>Archived</span>
											</v-tooltip>
										</v-col>
										<v-col cols="4">
											<EntitySelector v-model="selected[candidate.externalKey]" :items="unlinkedEntities" :suggested="candidate.suggested"/>
										</v-col>
										<v-col cols="2">
											<v-btn x-small text color="primary" @click="match(candidate)" >
												Match
											</v-btn>
										</v-col>
									</v-row>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</v-col>
			</v-row>
		</v-card-text>
	</div>	
</template>

<script>
import { mapState } from 'vuex';
import CandidateInfo from '@/components/users/CandidateInfo';
import EntitySelector from '@/components/util/EntitySelector';
import DialogError from '@/components/util/DialogError';
import _ from 'lodash';
import EntityNameHelper from '@/components/util/EntityNameHelper';

export default {
	name: "ManualUnmatched",
	props: {
		item: Object,
		type: String
	},

	components: {
		CandidateInfo,
		EntitySelector,
		DialogError
	},

	computed: {
		...mapState('candidates', [ 'candidates', 'matched', 'loaded', 'includeArchived']),
		...mapState('entities', [ 'entities' ]),
		unlinkedEntities()
		{
			return _.reject(this.entities, (entity)=>{
				return !!entity.integrations[this.item.integrationId];
			});
		},
		showArchived: {
			get()
			{
				return this.includeArchived;
			},
			set(value)
			{
				this.$store.dispatch('candidates/setIncludeArchived', { integrationId: this.item.integrationId, type: this.type, value});
			}
		},
		typeName()
		{
			switch(this.type)
			{
				case 'CENSUS_LOCATION':
					return 'location';
				default:
					return 'user'

			}
		},
		height() 
		{
			return Math.min(this.candidates.length * this.itemHeight, this.maxHeight);
		}
	},

	methods: {
		async match(candidate)
		{
			const value = this.selected[candidate.externalKey];

			if(!value)
			{
				this.$refs.error.show(`Please selector a ${this.typeName} to match.`);
				return;
			}

			try {
				const { externalKey } = candidate;
				const { entityId } = value;
				await this.$store.dispatch('candidates/matchCandidate', { 
					integrationId: this.item.integrationId, 
					externalKey, 
					entityId,
					type: this.type 
				});
			} catch(e) {
				console.error(e);
				this.$refs.error.show(`Could match ${this.typeName}. Please try again later.`);
			}


		},
		getLabel(item)
		{
			if(!item)
			{
				return;
			}

			return EntityNameHelper.get(item) || item.externalKey || item.entityId;
			
		}
	},
	

	data: () => ({
		selected: {},
		itemHeight: 64,
		maxHeight: 400
	}),
};
</script>
