import Vue from 'vue'
import Vuex from 'vuex'

import configuration from '@/store/modules/configuration';
import integrations from '@/store/modules/integrations';
import stats from '@/store/modules/stats';
import rules from '@/store/modules/rules';
import entities from '@/store/modules/entities';
import candidates from '@/store/modules/candidates';
import user from '@/store/modules/user';
import history from '@/store/modules/history';
import oauth from '@/store/modules/oauth';
import hipaa from '@/store/modules/hipaa';

import projects from '@/store/modules/developer/projects';
import schemas from '@/store/modules/developer/schemas';
import logs from '@/store/modules/developer/logs';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		configuration,
		integrations,
		stats,
		projects,
		schemas,
		logs,
		rules,
		entities,
		candidates,
		user,
		history,
		oauth,
		hipaa
	}
})
