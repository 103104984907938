<template>
	<v-container fluid class="sidebar pa-5 align-baseline" fill-height align-content-start>
		<v-row v-if="inputs.length > 0">
			<v-col class="text-center">
				Inputs 
			</v-col>	
		</v-row>
		<v-row dense class="text-left" >
			<v-col v-for="(item,i) in inputs" :key="item.integrationId" :cols="12">
				<IntegrationCard :item="item" type="input" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {mapState} from 'vuex';
import IntegrationCard from '@/components/configure/IntegrationCard';

export default {
	name: "Inputs",

	components: {
		IntegrationCard
	},

	computed: {
		...mapState('configuration', ['inputs'])
	},

	data: () => ({
		
	}),
};
</script>
