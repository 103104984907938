import config from '@/api/config';

const state = () => ({
	list: []
});


const getters = {
};

const mutations = {
	
};

const actions = {
	poll: async ({commit}, {integrationId, key}) => {
		return config.getKeyValue(integrationId, key);
	},

	getState: async ({commit}, {integrationId}) => {
		const { data: { state }} = await config.getState(integrationId);
		return state;
	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};