import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	logs: []
});


const getters = {
};

const mutations = {
	setLogs: (state, logs) => {
		state.logs = logs;
	},
	
};

const actions = {
	load: async ({commit}) => {
		const logs = await config.getHipaa();
		commit('setLogs', logs);

	},

	async logConnection({dispatch}, { from, to, purpose })
	{
		await config.logConnection(from, to, purpose);
	},
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};