<template>
	<div>
		<DialogError ref="error"/>
		<v-card-title align="left">
		</v-card-title>
		<v-card-text>
			<v-row class="text-body-1">
				<v-col cols="12">
					Current matched {{typeName}}s for {{item.name}}:
				</v-col>
			</v-row>
			<v-row v-if="matched.length == 0 && loaded" class="text-center"> 
				<v-col cols="12">
					No manual matches found.
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<v-virtual-scroll :items="matched" :height="height" :item-height="itemHeight" >
						<template v-slot:default="{ item: match }">
							<v-list-item :key="match.entityId">
								<v-list-item-content>
									<v-row dense class="align-center">
										<v-col cols="4">
											{{getLabel(getCandidate(match))}}
										</v-col>
										<v-col cols="2">
											<v-menu>
												<template v-slot:activator="{ on, attrs }">
													<v-btn x-small text icon v-bind="attrs" v-on="on">
														<v-icon>mdi-information</v-icon>
													</v-btn>
												</template>
												<CandidateInfo :candidate="getCandidate(match)" />
											</v-menu>
											
										</v-col>
										<v-col cols="4">
											{{getLabel(getEntity(match))}}
										</v-col>
										<v-col cols="2">
											<v-btn x-small text color="primary" @click="unlink(match)" >
												Unlink
											</v-btn>
										</v-col>
									</v-row>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</v-col>
			</v-row>
		</v-card-text>
		<v-dialog v-model="showUnlink" :max-width="600" persistent>
			<UnlinkMatch :item="item" :match="selected" :type="type" @close="selected=null"/>
		</v-dialog>
	</div>	
</template>

<script>
import { mapState } from 'vuex';
import CandidateInfo from '@/components/users/CandidateInfo';
import DialogError from '@/components/util/DialogError';
import _ from 'lodash';
import EntityNameHelper from '@/components/util/EntityNameHelper';
import UnlinkMatch from '@/components/users/UnlinkMatch';

export default {
	name: "ManualMatched",
	props: {
		item: Object,
		type: String
	},

	components: {
		CandidateInfo,
		DialogError,
		UnlinkMatch
	},

	computed: {
		...mapState('candidates', [ 'candidates', 'matched', 'loaded']),
		...mapState('entities', [ 'entities' ]),
		showUnlink()
		{
			return !!this.selected;
		},
		typeName()
		{
			switch(this.type)
			{
				case 'CENSUS_LOCATION':
					return 'location';
				default:
					return 'user'

			}
		},
		height() 
		{
			return Math.min(this.matched.length * this.itemHeight, this.maxHeight);
		}
	},

	methods: {
		async match(candidate)
		{
			const value = this.selected[candidate.externalKey];

			if(!value)
			{
				this.$refs.error.show(`Please selector a ${this.typeName} to match.`);
				return;
			}

			try {
				const { externalKey } = candidate;
				const { entityId } = value;
				await this.$store.dispatch('candidates/matchCandidate', { 
					integrationId: this.item.integrationId, 
					externalKey, 
					entityId,
					type: this.type
				});
			} catch(e) {
				//console.error(e);
				this.$refs.error.show(`Could match ${this.typeName}. Please try again later.`);
			}


		},
		unlink(match)
		{
			this.selected = match;
		},

		getLabel(item)
		{
			if(!item)
			{
				return;
			}
			return EntityNameHelper.get(item) || item.externalKey || item.entityId;
			
		},

		getCandidate(match)
		{
			if(!match)
			{
				return;
			}

			const entity = _.find(this.entities, ['entityId', match.entityId]);
			
			if(!entity)
			{
				return;
			}
			return _.get(entity.integrations, this.item.integrationId);
			
		},
		getEntity(match)
		{
			if(!match)
			{
				return;
			}
			return _.find(this.entities, ['entityId', match.entityId]);
		}
	},
	

	data: () => ({
		selected: null,
		itemHeight: 64,
		maxHeight: 400
	}),
};
</script>
