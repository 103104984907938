<template>
	<v-card class="mt-0">
		<v-card-text>
			<ManualUnmatched :item="item" :type="type" />
			<v-divider class="my-12"/>
			<ManualMatched :item="item" :type="type"/>
		</v-card-text>
	</v-card>	
</template>

<script>
import _ from 'lodash';
import ManualMatched from '@/components/users/ManualMatched';
import ManualUnmatched from '@/components/users/ManualUnmatched';


export default {
	name: "ManualCard",
	props: {
		item: Object,
		type: String
	},

	components: {
		ManualMatched,
		ManualUnmatched
	},
	
	watch: {
		async item()
		{
			await this.load();
		},
		async type()
		{
			await this.load();
		}
	},

	async mounted()
	{
		await this.load();
	},

	methods: {

		async load()
		{
			await this.$store.dispatch('entities/load', { type: this.type });
			await this.$store.dispatch('candidates/load', { integrationId: this.item.integrationId, type: this.type});
		}

	},


	data: () => ({
		prevType: null
	}),
};
</script>
