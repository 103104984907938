<template>
	<v-container fluid>
		<vgl-defs>
			<template #ring>
				<vgl-mesh-standard-material :color="grid.color"></vgl-mesh-standard-material>
			</template>
		</vgl-defs>
		<vgl-renderer ref="renderer" class="renderer" antialias>
			<template #scene>
				<vgl-scene ref="scene" :background="colors.background">
					<vgl-mesh geometry="inner" material="ring" :rotation-x="grid.rotation.x" :rotation-y="grid.rotation.y" :rotation-z="grid.rotation.z">
						<template #geometry>
							<vgl-ring-geometry
							:theta-segments="grid.segments"
							:inner-radius="grid.radius / 2 - (grid.width / 2)"
							:outer-radius="grid.radius / 2 + (grid.width / 2)">
						</vgl-ring-geometry>
						</template>
						<template #material>
							<vgl-use href="ring" />
						</template>
					</vgl-mesh>
					<vgl-mesh geometry="outer" material="ring" :rotation-x="grid.rotation.x" :rotation-y="grid.rotation.y" :rotation-z="grid.rotation.z">
						<template #geometry>
							<vgl-ring-geometry
								:theta-segments="grid.segments"
								:inner-radius="grid.radius - (grid.width / 2)"
								:outer-radius="grid.radius + (grid.width / 2)">
							</vgl-ring-geometry>
						</template>
						<template #material>
							<vgl-use href="ring" />
						</template>
					</vgl-mesh>
					<vgl-ambient-light :intensity="0.5" />
					<vgl-directional-light :position-x="0" :position-y="2" :position-z="1" :intensity="0.5"/>
				</vgl-scene>
			</template>
			<template #camera>
				<vgl-perspective-camera 
					ref="camera" 
					:position-x="view.position.x" 
					:position-y="view.position.y" 
					:position-z="view.position.z"
					:fov="view.fov"
					:zoom="view.zoom"
					:far="view.far"
					:near="view.near"
					/>
			</template>
		</vgl-renderer>
		<div class="text-overline mt-5">[Beta] Coming soon...</div>
		<div class="caption">Currently represents connections, but not actual data statistics.</div>
	</v-container>
</template>

<script>

/* eslint vue/no-unused-components:"off" */

import {mapState} from 'vuex';
import _ from 'lodash';
import moveAlong from '@/components/util/MoveAlong';
import { VglDefs, VglUse, VglRenderer, VglScene, VglMesh, VglPerspectiveCamera, VglRingGeometry, VglMeshStandardMaterial, VglAmbientLight, VglDirectionalLight } from 'vue-gl';
import TWEEN from '@tweenjs/tween.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import * as Stats from 'stats.js'

const THREE = require('three');

export default {
	name: "Stats",

	components: {
		VglDefs, VglUse, VglRenderer, VglScene, VglMesh, VglPerspectiveCamera, VglRingGeometry, VglMeshStandardMaterial, VglAmbientLight, VglDirectionalLight
	},

	computed: {
		...mapState('configuration', ['inputs', 'outputs']),
		...mapState('stats', ['list']),
		meshes()
		{
			this.reload();
			return _.concat(this.inner, this.outer);
		},
		all()
		{
			return _.concat(this.inputs, this.outputs);
		}
		
	},

	mounted()
	{
		this.setupBenchmarks();
		this.reload();
	},

	destroyed()
	{
		this.removeBenchmarks();
	},

	methods: {


		setupBenchmarks()
		{
			if(process.env.NODE_ENV === 'development' && this.benchmark.enabled)
			{
				//console.log('Setting up benchmarks');

				let mem = new Stats();
				let fps = new Stats();

				fps.showPanel( 0 ); 
				let el1 = document.body.appendChild(fps.dom);

				mem.showPanel( 2 ); 
				let el2 = document.body.appendChild(mem.dom);
				el2.style.left = '80px';

				this.benchmark.mem = mem;
				this.benchmark.fps = fps;
				this.benchmark.elements = { mem: el2, fps: el1 };

			}

		},

		removeBenchmarks()
		{
			if(this.benchmark.enabled)
			{
				//console.log('Removing benchmarks');
				document.body.removeChild(this.benchmark.elements.mem);
				document.body.removeChild(this.benchmark.elements.fps);
			}
			
		},

		async reload()
		{
			await this.$store.dispatch('configuration/load');
			await this.$store.dispatch('stats/load');

			//reset starting angles
			this.resetPositions();

			this.build();
		},

		loadFont()
		{
			let loader = new THREE.FontLoader();

			return new Promise((resolve)=>{
				loader.load( this.text.font, (font) => {
					resolve(font);
				});
			});
		},


		async build()
		{
			let { renderer, camera, scene } = this.$refs;
			let { labels, benchmark } = this;

			if(!scene || this.built)
			{
				return;
			}

			this.font = await this.loadFont();

			this.scene = scene.inst;
			this.camera = camera.inst;
			this.renderer = renderer.inst;

			_.forEach(this.outputs, (output)=> {
				this.buildCylinder(output, false);
			});

			_.forEach(this.inputs, (input)=> {
				this.buildCylinder(input, true);
			});

			this.buildArcs();

			// Setup the animation loop.
			function animate(time) {
				

				benchmark.begin();

				_.forEach(labels, (label) => {
					label.lookAt( camera.inst.position );
				});

				TWEEN.update(time);
				renderer.render(scene.inst, camera.inst);

				benchmark.end();

				requestAnimationFrame(animate)
				
			}

			requestAnimationFrame(animate);

			this.controls = new OrbitControls(this.camera, this.$parent.$el)

			this.built = true;
		},

		buildCylinder(item, inner)
		{
			let { radius, height, segments} = this.cylinder;

			let {x,y,z} = this.nextPosition(inner);

			let geometry = new THREE.CylinderGeometry( radius, radius, height, segments );
			let material = new THREE.MeshStandardMaterial( {color: this.colors.cylinder} );
			let cylinder = new THREE.Mesh( geometry, material );
			
			this.scene.add(cylinder);
			this.cylinders.push(cylinder);

			cylinder.position.set(x, y, z);

			cylinder.integrationId = item.integrationId;
			cylinder.type = (inner) ? 'input' : 'output';

			this.buildLabel(item, inner, cylinder)
		},

		buildLabel(item, inner, cylinder)
		{
			let { size, height, yoffset} = this.text;
			
			let geometry = new THREE.TextGeometry( item.name, {
				font: this.font,
				size,
				height
			});
			
			geometry.computeBoundingBox();

			let material = new THREE.MeshStandardMaterial( {color: this.colors.text } );
			let text = new THREE.Mesh( geometry, material );

			let {x,y,z} = cylinder.position;
			text.position.set(x, y + yoffset, z);


			text.lookAt(this.camera.position);

			this.scene.add(text);
			this.labels.push(text);

			text.integrationId = item.integrationId;
			text.type = (inner) ? 'input' : 'output';

		},


		buildArcs()
		{

			_.forEach(this.inputs, (input) => {
				
				//loop through connections
				_.forEach(input.connections, (connection) => {

					const from = _.find(this.cylinders, { integrationId: input.integrationId, type: 'input' });
					const to = _.find(this.cylinders, { integrationId: connection.toIntegrationId, type: 'output' });
					this.buildArc(from, to);

				});
				
			});
		
		},

		buildArc(from, to)
		{
			const key = `${from.integrationId}_${to.integrationId}`;
			if(this.curves[key])
			{
				return;
			}

			const curve = this.addCurve(from.position, to.position);
			this.curves[key] = curve;

			//TODO - add dots based on stats

			//console.log(this.stats);

			let total = (2) * 7;
			let speed = (5) * 50;
			let size = (2) * .025 + .15;

			for(let i=0; i < total; i++)
			{
				this.addDot(curve, size, speed, i, total);
			}
		},

		addCurve(to, from)
		{
			let { scene } = this.$refs;

			let curve = new THREE.QuadraticBezierCurve3(
				new THREE.Vector3( to.x, this.cylinder.height, to.z ),
				new THREE.Vector3( (to.x + from.x) / 2, this.cylinder.height * 5, (to.z + from.z) / 2 ),
				new THREE.Vector3( from.x, this.cylinder.height, from.z )
			);

			let points = curve.getPoints( 50 );
			let geometry = new THREE.BufferGeometry().setFromPoints( points );

			let lineMaterial = new THREE.LineBasicMaterial( { color : 0xaaaaaa } );

			let curveObject = new THREE.Line( geometry, lineMaterial );

			scene.inst.add( curveObject );

			return curve;
		},

		addDot(curve, size, speed, unit, total)
		{
			let { scene } = this.$refs;

			let duration = curve.getLength();
			let length = duration * speed;
			let delay = unit * (length / total);

			let dot = new THREE.SphereGeometry( size, 10, 10 );
			let dotMaterial = new THREE.MeshBasicMaterial( {color: 0xff6600} );
			let dotObject = new THREE.Mesh( dot, dotMaterial );

			scene.inst.add( dotObject );

			let tween = moveAlong(dotObject, curve, {
				repeat: true,
				delay,
				speed
			});
		},

		nextPosition(inner)
		{			
			
			//calculate the next position on the ring

			let coords = (inner) ? this.coords.inner : this.coords.outer;

			let x = coords.radius *  Math.cos(coords.angle)
			let z = coords.radius *  Math.sin(coords.angle)
			let y = this.cylinder.height / 2;

			let pos = { x, y, z, angle: coords.angle };
			
			coords.angle += coords.increment;

			return pos;
		},

		resetPositions()
		{
			//TODO - calculate radius and increment based on quantity
			this.coords.inner = { radius: this.grid.radius / 2, angle: 0, increment: .5 };
			this.coords.outer = { radius: this.grid.radius, angle: 0, increment: .5 };
		}
	},

	data: () => ({

		cylinders: [],
		labels: [],
		curves: {},
		coords: {
			inner: {},
			outer: {}
		},

		view: {
			far: 10000,
			near: .1,
			fov: 50,
			zoom: 1,
			position: {
				x: 100,
				y: 50,
				z:  .5
			},

			target: {
				x:0,
				y:1,
				z:0
			}
		},
		colors: {
			background: '#fff',
			cylinder: '#ccc',
			text: '#888'
		},
		cylinder: {
			height: 5,
			radius: 2,
			segments: 32
		},
		grid: {
			color: '#f5f5f5',
			segments: 100,
			radius: 50,
			rotation: {
				x: THREE.Math.degToRad(-90),
				y: 0,
				z: 0
			},
			width: 2
		},
		text: {
			size: .75,
			height: .1,
			font: '/fonts/helvetiker_regular.typeface.json',
			yoffset: 6
		},
		benchmark: {
			enabled: false,
			begin() {
				if(this.enabled)
				{
					this.fps.begin();
					this.mem.begin();
				}
			},
			end() {
				if(this.enabled)
				{
					this.fps.end();
					this.mem.end();
				}
			}
		}
	})
};

</script>

<style>
.renderer {
	width: 100%;
	height: calc(100vh - 300px);
	border: 1px solid #ccc;
}
</style>
