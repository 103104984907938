import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	candidates: [],
	matched: [],
	loaded: false,
	includeArchived: false
});

const getters = {
	
};


const mutations = {
	setCandidates: (state, candidates) => {
		state.candidates = candidates;
	},
	setMatched: (state, matched) => {
		state.matched = matched;
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
	setIncludeArchived: (state, value) => {
		state.includeArchived = value;
	}
};

const actions = {
	load: async ({commit, state}, { integrationId, type}) => {
		
		const { includeArchived } = state;
		const candidates = await config.getCandidates(integrationId, type, includeArchived);
		const matched = await config.getMatched(integrationId, type);
		const suggested = await config.getSuggested(integrationId, type);

		_.each(suggested, (suggestion)=>{
			const candidate = _.find(candidates, ['externalKey', suggestion.candidate.externalKey]);
			if(candidate)
			{
				candidate.suggested = suggestion;
			}
		});
		
		commit('setCandidates', candidates);
		commit('setMatched', matched);
		commit('setLoaded', true);

	},

	async matchCandidate({dispatch}, { integrationId, externalKey, entityId, type })
	{
		await config.matchCandidate(integrationId, externalKey, entityId);
		await dispatch('entities/load', { type }, {root:true});
		await dispatch('load', { integrationId, type });
	},

	async unlinkCandidate({dispatch}, { integrationId, entityId, type })
	{
		await config.unlinkCandidate(integrationId, entityId);
		await dispatch('entities/load', { type }, {root:true});
		await dispatch('load', { integrationId, type });
		
	},

	async setIncludeArchived({commit, dispatch}, { integrationId, type, value})
	{
		commit('setIncludeArchived', value);
		await dispatch('load', { integrationId, type });
	}

};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};
