<template>
	<v-container fluid class="pa-0" fill-height align-content-start>
		<v-row v-if="$vuetify.breakpoint.mdAndUp && list.length > 0" class="fill-height"> 
			<v-col cols="3" class="py-0">
				<Side @select="select"/>
			</v-col>
			<v-col cols="9" class="py-0">
				<Details v-if="selected" :item="selected"/>
				<v-container v-else>
					<v-tabs v-model="tab" grow>
						<v-tab>Users</v-tab>
						<v-tab>Locations</v-tab>
					</v-tabs>
					<EntitiesList v-if="tab === 1" type="CENSUS_LOCATION" title="Known Locations" :columns="locationColumns"/>
					<EntitiesList v-else type="CENSUS_USER" title="Known Users" :columns="userColumns"/>
				</v-container>
			</v-col>
		</v-row>
		<v-row v-if="loaded && $vuetify.breakpoint.mdAndUp && list.length == 0" class="mt-12"> 
			<v-col cols="12">
				No integrations enabled that import users. Go to the <router-link to="/configure">configure</router-link> tab to get started.
			</v-col>
		</v-row>
		<v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-12"> 
			<v-col cols="12" class="text-overline">
				Configuration not available on small screen devices.
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>

import Side from '@/components/users/Side';
import Details from '@/components/users/Details';
import EntitiesList from '@/components/util/EntitiesList';

import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

export default {
	name: "Users",

	components: {
		Side,
		Details,
		EntitiesList
	},

	computed: {
		...mapGetters('configuration', ['filtered']),
		...mapState('configuration', ['loaded']),
		list()
		{
			//get only input integrations that can produce users
			return this.filtered('inputs', 'io.fusionos.sdk.v1.census.Create');
		}
	},
	
	methods: {
		select(value)
		{
			this.selected = value;
		}
	},

	created () {
		this.$store.dispatch('configuration/load');
	},

	data: () => ({
		tab: null,
		selected: null,
		userColumns: [
			{ text: 'First', value: 'properties.firstName' },
			{ text: 'Last', value: 'properties.lastName' },
			{ text: 'Linked Integrations', value: 'integrations' },
			{ text: 'Properties', value: 'properties' },
			{ text: 'Status', value: 'archived' }
		],
		locationColumns: [
			{ text: 'Name', value: 'properties.name' },
			{ text: 'Linked Integrations', value: 'integrations' },
			{ text: 'Properties', value: 'properties' },
			{ text: 'Status', value: 'archived' }
		]
	}),
};
</script>
