<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Add New Project</v-card-title>
		<v-card-text>
			<IntegrationDetailsForm ref="form" v-model="item" @valid="validate" />
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text :disabled="!valid" @click="save">Save</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';
import IntegrationDetailsForm from '@/components/developer/IntegrationDetailsForm';

export default {
	name: "NewProject",

	components: {
		DialogError,
		IntegrationDetailsForm
	},

	methods: {
		async save()
		{
			const { name, description } = this.item;
			try {
				await this.$store.dispatch('projects/addProject', { name, description });
			} catch(e) {
				console.error(e);
				this.$refs.error.show('Could not create new integration. Please try again later.');
				return;
			}

			this.close();

		},
		close()
		{
			this.$refs.form.reset();
			this.item = {};
			this.$emit('close');
		},
		validate(value)
		{
			this.valid = value;
		}
	},

	data: () => ({
		item: {},
		valid: false,
		nameRules: [
			v => !!v || 'Name is required',
			v => v.length <= 36 || 'Name must be less than 36 characters'
		],
		descriptionRules: [
			v => !!v || 'Description is required',
			v => v.length <= 1024 || 'Name must be less than 1024 characters'
		]
	}),
};
</script>
