<template>
	<v-card :width="400">
		<v-card-title v-if="title">{{title}}</v-card-title>
		<v-card-text align="left">
			<v-row v-for="(property, i) in candidate.properties" :key="i" dense>
				<v-col cols="4">	
					{{getLabel(i)}}
				</v-col>
				<v-col cols="8">
					<div v-if="typeof property === 'object'">
						<v-row v-for="(sub, j) in property" :key="j" dense>
							<!--only nest one level deep-->
							<template v-if="typeof sub === 'string' || typeof sub === 'number'" >
								<v-col cols="4" class="grey--text">	
									{{getLabel(j)}}
								</v-col>
								<v-col cols="8">
									{{sub}}
								</v-col>
							</template>
							<template v-if="typeof sub === 'object'" >
								<v-row v-for="(k, l) in sub" :key="l" dense>
									<v-col v-if="l === 'name'" cols="12" class="grey--text">	
										{{truncateText(k, 16)}}
									</v-col>
							</v-row>
							</template>
						</v-row>
					</div>
					<div v-else>{{property}}</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>

import _ from 'lodash';

export default {
	name: "CandidateInfo",

	props: {
		candidate: Object,
		title: String
	},

	methods: {
		getLabel(text)
		{
			return _.startCase(text);
		},
		truncateText(text, maxLength) {
			if (text.length > maxLength) {
				return text.slice(0, maxLength) + '...';
			}
			return text;
		}
	},

	data: () => ({
	}),
};
</script>
