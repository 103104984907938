<template>
	<v-container fluid class="pa-5 text-left">
		<v-card v-if="schema">
			<v-card-title>
				{{schema.domain}} {{schema.name}} History
			</v-card-title>
			<v-row >
				<v-col cols="4"></v-col>
				<v-col cols="3" class="history-dates">
					<v-datetime-picker  v-model="start" label="Start">
						<template v-slot:dateIcon><v-icon>mdi-calendar</v-icon></template>
						<template v-slot:timeIcon><v-icon>mdi-clock</v-icon></template>
					</v-datetime-picker>
				</v-col>
				<v-col cols="3" class="history-dates">
					<v-datetime-picker v-model="end" label="End">
						<template v-slot:dateIcon><v-icon>mdi-calendar</v-icon></template>
						<template v-slot:timeIcon><v-icon>mdi-clock</v-icon></template>
					</v-datetime-picker>
				</v-col>
				<v-col cols="2">
					<v-btn text color="primary" @click="reload(null)">
						Reload
					</v-btn>
				</v-col>
				<v-col cols="12">
					<v-data-table :headers="headers" :items="list" :loading="!loaded" :footer-props="{ showFirstLastPage: true }">
						<template v-slot:item.dateTime="{ item }">
							{{ item.dateTime | formatDatetime}}
						</template>
						<template v-slot:item.sender="{ item }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-img :src="item.sender.icon" :height="24" :width="24" v-bind="attrs" v-on="on"/>
								</template>
								<span>{{item.sender.name}}</span>
							</v-tooltip>
						</template>
						<template v-slot:item.data="{ item }">
							<span class="caption">{{preview(item.data)}}</span>
						</template>

						<template v-slot:item.actions="{ item }">
							<v-dialog v-model="dialog[item.id]">
								<template v-slot:activator="{ on, attrs }">
									<v-btn x-small text icon v-bind="attrs" v-on="on">
										<v-icon>mdi-information</v-icon>
									</v-btn>
								</template>
								<SchemaInfo :schema="item.data" @close="dialog[item.id] = false" />
							</v-dialog>
						</template>

						<template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
							<span v-if="itemsLength > 0">{{pageStart}} - {{pageStop}} of {{itemsLength}}</span>
							<v-btn v-if="nextFile" color="primary" text class="ma-2" @click="more">
								More
							</v-btn>
						</template>						
					</v-data-table>	
				</v-col>
			</v-row>
		</v-card>
		<v-row v-else>
			<v-col cols="10" class="text-center">
				Select an event type to get started.
			</v-col>
		</v-row>
		
	</v-container>
	
</template>

<script>
import { mapState } from 'vuex';
import SchemaInfo from '@/components/util/SchemaInfo';
import _ from 'lodash';

export default {
	name: "EventDetails",
	props: {
		schema: Object
	},

	components: {
		SchemaInfo
	},

	computed: {
		...mapState('history', ['list', 'loaded', 'nextFile']),
		...mapState('configuration', { integrations: 'list'})
	},

	watch: {
		schema()
		{
			this.reload();
		}
	},

	mounted()
	{
		this.reload();
	},

	methods: {

		async more()
		{
			await this.reload(this.nextFile);
		},
		async reload(more = null)
		{
			if(!this.schema)
			{
				return;
			}

			const { start, end } = this;
			const cls = this.schema.cls;
			this.$store.dispatch('history/load', { cls, start: start.toISOString(), end: end.toISOString(), more  })
		},

		preview(obj)
		{
			const keys = _.keys(obj);
			const schema = obj[keys[0]];
			let list = [];
			_.each(schema, (prop, i) => {
				if(typeof prop !== 'object')
				{
					list.push(`${_.startCase(i)}: ${prop}`);
				}
			})
			return list.join(', ');
		},


	},

	data: () => {

		const start = new Date();
		const end = new Date();

		start.setHours(start.getHours() - 1);

		return {
			dialog: {},
			start,
			end,
			headers: [ 
				{ text: 'Timestamp', value: 'dateTime', width: 150 },
				{ text: 'Sender', value: 'sender', sortable: false },
				{ text: 'Preview', value: 'data', sortable: false },
				{ text: 'Data', value: 'actions', sortable: false }
			]
		}
	},
};
</script>

<style>

.history-viewer .line-number {
	display: none;
}
.history-viewer .line-content {
	font-family: monospace;
}

.history-viewer {
	width: 100%;
}

.history-dates .v-text-field__details {
	display: none;
}
.history-dates .v-input__slot {
	margin-bottom: 0;
}


</style>
