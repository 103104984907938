import env from '@/api/env';
import _ from 'lodash';

var state = {
	cognitoInfo: {},
	loggedIn: false,
	loadingState: true,
	errorLoadingState: false,
	configInfo: {},
	organizations: []
}

function setLoggedIn(newValue) {
	state.loggedIn = newValue;
}

function setLoggedOut() {
	state.loggedIn = false;
	state.cognitoInfo = {};
}

function setCognitoInfo(newValue){
	state.cognitoInfo = newValue;
}

function setOrganizations(newValue){

	if(Array.isArray(newValue))
	{
		const id = localStorage.getItem('selectedOrganizationId');
		const found = _.find(newValue, [ 'organizationId', id ]);
		if(id && found)
		{
			setConfigInfo(found);
		} else { 
			setConfigInfo(newValue[0]);
		}
		state.organizations = newValue;
	} else {
		setConfigInfo(newValue);
		state.organizations = [ newValue ];
	}
		
}

function setConfigInfo(newValue){
	state.configInfo = newValue;
	localStorage.setItem('selectedOrganizationId', newValue.organizationId);
}

const getters = {
	
	configInfo(state)
	{
		return state.configInfo
	},
	environment()
	{	
		return env.name();
	},
	organizationId(state)
	{
		return (state.cognitoInfo) ? state.configInfo.organizationId : null;
	},
	organizationName(state)
	{
		return (state.cognitoInfo) ? state.configInfo.name : null;
	},
	isCoveredEntity(state)
	{
		return state.configInfo.hipaa;
	}
};

const actions = {

	select: async ({state}, organization) => {

		if(organization.organizationId !== state.cognitoInfo.organizationId)
		{
			setConfigInfo(organization);
			window.location.reload();
		}
		

	}
};

export default {
	namespaced: true,
	state: state,
	setLoggedIn: setLoggedIn,
	setLoggedOut: setLoggedOut,
	setCognitoInfo: setCognitoInfo,
	setConfigInfo: setConfigInfo,
	setOrganizations: setOrganizations,
	getters,
	actions
}