import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: [],
	loaded: false,
	nextFile: null
});


const getters = {
};

const mutations = {
	setHistory: (state, { history, append }) => {

		if(append)
		{
			state.list = _.concat(state.list, history);
		} else {
			state.list = history;
		}
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},

	setNextFile: (state, nextFile) => {
		state.nextFile = nextFile;
	},
	
};

const actions = {
	load: async ({commit, rootState }, { cls, start, end, more }) => {

		commit('setLoaded', false);
		
		const inputs = rootState.configuration.inputs;

		const { nextFile, data } = await config.getHistory(cls, start, end, more);

		const list = [];

		_.each(data, (row)=>{

			const data = JSON.parse(row);			
			const { id, sender, dateTime } = data.headers;
			delete data.headers;

			const obj = {
				id: sender
			}

			const integration = _.find(inputs, ['integrationId', sender]);
			obj.name = (integration) ? integration.name : 'Unknown';
			obj.icon = (integration) ? integration.icon : null;	
			list.push({ id, sender: obj, dateTime, data});
		})

		commit('setNextFile', nextFile);
		commit('setHistory', { history: list, append: !!more });
		commit('setLoaded', true);

	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};