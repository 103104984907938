import Vue from 'vue'
import VueRouter from 'vue-router'
import Config from '@/views/Config.vue'
import Users from '@/views/Users.vue'
import History from '@/views/History.vue'
import Hipaa from '@/views/Hipaa.vue'
import Home from '@/views/Home.vue'
import Projects from '@/views/developer/Projects.vue'
import Details from '@/views/developer/Details.vue'
import Logs from '@/views/developer/Logs.vue'
import Test from '@/views/developer/Test.vue'
import Tools from '@/views/developer/Tools.vue'
import Versions from '@/views/developer/Versions.vue'
import Entities from '@/views/developer/Entities.vue'
import store from '@/store/index'
import user from '@/store/modules/user'

import auth from '@/api/auth';
import LogoutSuccess from '@/components/auth/LogoutSuccess';
import config from '@/api/config';
import env from '@/api/env';
import ErrorComponent from '@/components/auth/Error';
import { _ } from 'core-js'

Vue.use(VueRouter)



function requireAuth(to, from, next) {

	if (!auth.cognito.isUserSignedIn()) {

		user.setLoggedIn(false);
		next({
			path: '/login',
			query: { redirect: to.fullPath }
		});
	} else {

		const organizationId = store.getters['user/organizationId'];

		if(organizationId)
		{
			//user info is set
			return next();
		}

		//Get cognito user info
		config.getUserInfo().then(response => {
			
			user.setCognitoInfo(response);
			
			//get user info from config service
			config.getCurrentUser().then(response => {

				user.setOrganizations(response);			
				user.setLoggedIn(true);
				next();
			});

		});
		
	}
  }

const routes = [
	{
		path: '/login', beforeEnter(to, from, next){
			auth.cognito.getSession();
		}
	},
	{
		path: '/login/oauth2/code/cognito', beforeEnter(to, from, next){
			var currUrl = window.location.href;
			auth.cognito.parseCognitoWebResponse(currUrl);
			//next();
		}
	},
	{
		path: '/logout', component: LogoutSuccess,  beforeEnter(to, from, next){
			auth.logout();
			next();
		}

	},
	{
		path: '/error', component: ErrorComponent
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: requireAuth
	},
	{
		path: '/configure',
		name: 'Configure',
		component: Config,
		beforeEnter: requireAuth
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		beforeEnter: requireAuth
	},
	{
		path: '/history',
		name: 'History',
		component: History,
		beforeEnter: requireAuth
	},
	{
		path: '/hipaa',
		name: 'Hipaa',
		component: Hipaa,
		beforeEnter: requireAuth
	}
];

const developerRoutes = [
	{
		path: '/developer',
		name: 'Projects',
		component: Projects,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/details',
		name: 'Details',
		component: Details,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/logs',
		name: 'Logs',
		component: Logs,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/test',
		name: 'Test',
		component: Test,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/tools',
		name: 'Tools',
		component: Tools,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/versions',
		name: 'Versions',
		component: Versions,
		beforeEnter: requireAuth
	},
	{
		path: '/developer/:id/entities',
		name: 'Entities',
		component: Entities,
		beforeEnter: requireAuth
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: (env.name() === 'production') ? routes : _.concat(routes, developerRoutes)
});




router.afterEach(async (to, from, ) => {

	if(to.params.id && to.params.id !== from.params.id)
	{
		await store.dispatch('projects/select', to.params.id)
	} 
	
});

export default router
