<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Disable Connection</v-card-title>
		<v-card-text class="text-left">
			<v-row>
				<div class="v-subheader theme--light pa-0">When {{connection.schemas.input.name}} is received from {{connection.input.name}}, {{connection.schemas.output.name}} will be called on {{connection.output.name}} </div>
			</v-row>
			<v-row>
				Are you sure you want to disable this connection?
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text @click="save">Disable</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "ConnectionDisable",

	props: {
		connection: Object
	},
	
	components: {
		DialogError
	},

	methods: {
		async save()
		{
			try {
				await this.$store.dispatch('configuration/deleteConnection', {
					from: this.connection.fromIntegrationId,
					to: this.connection.toIntegrationId,
					cls: this.connection.cls
				});
			} catch(e) {
				// console.error(e);
				this.$refs.error.show('Could not disable connection. Please try again later.');
				return;
			}

			this.close();

		},
		close()
		{
			this.selected = null;
			this.$emit('close');
		}
	},

	data: () => ({
	}),
};
</script>
