<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Unlink Match</v-card-title>
		<v-card-text class="text-left">
			<v-row>
				Are you sure you want to unlink this match?
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text @click="save">Unlink</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "UnlinkMatch",

	props: {
		item: Object,
		match: Object,
		type: String
	},

	components: {
		DialogError
	},

	methods: {
		async save()
		{
			try {
				const { integrationId } = this.item;
				const { entityId } = this.match;
				await this.$store.dispatch('candidates/unlinkCandidate', { integrationId, entityId, type: this.type });
			} catch(e) {
				//console.error(e);
				this.$refs.error.show('Could unlink user. Please try again later.');
			}

			this.close();

		},
		close()
		{
			this.$emit('close');
		}
	},

	data: () => ({
	}),
};
</script>