<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Add New Matching Rule</v-card-title>
		<v-card-text>
			<v-row align="center" justify="center">
				<v-col cols="5">
					<v-row align="center">
						<v-col>
							<IntegrationSelector v-model="item" :items="[item]" readonly />
						</v-col>
						<v-col>
							<v-select v-model="sourceField" label="Select a field" outlined :items="unique" />
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="2" class="mb-7 text-center">
					<v-icon v-if="sourceField" large>mdi-equal</v-icon>
				</v-col>
				<v-col cols="5">
					<v-row align="center">
						<v-col>
							<IntegrationSelector v-if="sourceField" v-model="target" :items="list" />
						</v-col>
						<v-col>
							<v-select v-if="target" v-model="targetField" outlined :items="unique" />
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn text @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text :disabled="!valid" @click="save">Save</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import IntegrationSelector from '@/components/util/IntegrationSelector';
import DialogError from '@/components/util/DialogError';

import _ from 'lodash'

export default {
	name: "RulesCard",
	props: {
		item: Object,
		type: String
	},
	components:{
		IntegrationSelector,
		DialogError
	},
	computed: {
		...mapGetters('configuration', ['filtered']),
		...mapState('rules', [ 'rules' ]),
		list()
		{
			//get only input integrations that can produce users
			const list = this.filtered('inputs', 'io.fusionos.sdk.v1.census.Create');
			return _.reject(list, ['integrationId', this.item.integrationId]);
		},
		valid()
		{
			return this.sourceField !== null 
				&& this.target !== null 
				&& this.targetField !== null 
		},
		unique()
		{
			const { users, locations } = this.item.implementation.matching;

			if(this.type === 'CENSUS_USER')
			{
				return (users) ? users.unique : [];
			} else {
				return (locations) ? locations.unique : [];
			}
		}
	},
	mounted()
	{

	},

	methods: {
		async save()
		{

			try {
				await this.$store.dispatch('rules/addRule', {
					sourceIntegrationId: this.item.integrationId,
					sourceField: this.sourceField,
					targetIntegrationId: this.target.integrationId,
					targetField: this.targetField,
					type: this.type 
				});
			} catch(e) {
				if(e.response.status === 409)
				{
					this.$refs.error.show('An identical rule already exists.', 'warning');
					return;
				} else {
					console.error(e);
					this.$refs.error.show('Could not add rule. Please try again later.');
					return;
				}
			}			

			this.close();

		},
		close()
		{
			this.sourceField = this.targetField = this.target = null;
			this.$emit('close');
		}
	},

	
	

	data: () => ({
		sourceField: null,
		targetField: null,
		target: null
	}),
};
</script>
