<template>
	<v-container>
		<v-row>
			<v-col>
				Next, let's select the integration to send the data to.
			</v-col>	
		</v-row>
		<v-row>
			<v-col>
				<v-select v-model="output" outlined label="Destination" :items="list" item-text="name" return-object>
				</v-select>
				<v-select v-if="output" v-model="schema"  outlined label="Action" :items="schemas" item-text="name" return-object @change="selected">
				</v-select>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import _ from 'lodash';

export default {
	name: "ConnectionsDestination",


	props: {
		item: Object,
		value: Object
	},

	computed: {
		...mapGetters('configuration', ['filtered']),
		list()
		{
			if(!this.value || !this.value.input)
			{
				return [];
			}

			const outputs = this.filtered('outputs', this.value.input.cls);
			//dont allow connections to ourselves. May reconsider later.
			return _.reject(outputs, ['integrationId', this.item.integrationId]);
		},

		schemas()
		{
			if(!this.value || !this.value.input)
			{
				return [];
			}

			return _.filter(this.output.implementation.outputs, ['cls', this.value.input.cls ]);
		}
		
	},

	methods: {
		selected()
		{
			this.$set(this.value, 'destination', {
				output: this.output,
				schema: this.schema
			});
			
		}
	},

	data: () => ({
		output: null,
		schema: null
	}),
};
</script>
