import LeaderLine from 'leader-line-new';
import _ from 'lodash';

export default {

	lines: [],

	show(from, to)
	{

		this.hideAll();
		
		_.each(from, (start)=>{
			_.each(to, (end)=> {

				let startEl = document.getElementById(start.id);
				let endEl = document.getElementById(end.id);

				if(!endEl || !startEl)
				{
					//console.log('Missing', start, end, startEl, endEl);
					return;
				}

				const color = end.color || start.color;

				const line = new LeaderLine(startEl, endEl, { color, dash: {animation: true}});
				this.lines.push(line);
			});
		});
	},

	hideAll()
	{
		_.each(this.lines, (line) =>{
			line.remove();
		});

		this.lines = [];
	}
	
}