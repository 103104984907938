<template>
	<v-container fluid class="pa-8">
		<v-card>
			<v-card-title>Inject Data</v-card-title>
			<v-card-text class="text-left">
				<v-row>
					<v-col cols="12">
						Select an event type to send to the project:
					</v-col>
				</v-row>
				<v-row v-if="item.implementation">
					<v-col>
						<v-select v-model="model" label="Select Event..." outlined item-text="name"  item-value="cls" :items="item.implementation.outputs"/>
					</v-col>
				</v-row>
				<Inject v-if="selected" :item="item" :selected="selected"/>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

import store from '@/store/index'
import {mapState} from 'vuex';
import _ from 'lodash';
import Inject from '@/components/developer/Inject';


export default {
	name: "Test",

	computed: {
		...mapState('projects', [ 'item' ]),
		...mapState('schemas', [ 'list', 'selected' ]),
		model: {
			set(cls)
			{
				this.$store.dispatch('schemas/select', cls);
			},
			get()
			{
				if(this.selected)
				{
					return this.selected.cls;
				}
				return null;
			}
		}
	},
	components: {
		Inject
	},

	

	created () {
		this.$store.dispatch('schemas/load');
	},

	data: () => ({
	}),
};
</script>
