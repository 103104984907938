<template>
	<v-container :style="{ opacity: (visible) ? '1.0' : '.25' }" class="py-0" align-content-start fill-height>
		<v-row v-if="inputs.length > 0 || outputs.length > 0">
			<v-col cols="12" class="text-center">
				Summary of Connections 
			</v-col>
		</v-row>
		<v-row v-else-if="loaded" class="mt-9"> 
			<v-col cols="12">
				No integrations enabled. Click the <v-icon small color="primary">mdi-plus-circle</v-icon> button to get started.
			</v-col>
		</v-row>
		<v-row v-if="inputs.length > 0 || outputs.length > 0">
			<v-row v-if="count == 0" class="mt-9"> 
				<v-col cols="12">
					No connections made. Click the <span class="primary--text text-caption">ADD</span> button to get started.
				</v-col>
			</v-row>
			<v-list v-else subheader three-line color="grey lighten-5" width="100%" class="text-left">
				<template v-for="(group, key) in groupedConnections">
					<v-subheader  :key="key" inset class="text-overline">
						{{key}}
					</v-subheader>
					<v-list-item v-for="(connection, i) in group" :key="`${key}_${i}`">
						<v-list-item-avatar>
							<v-icon class="grey lighten-1" dark>{{icons[key] || icons.default}}</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="my-3">{{i}}</v-list-item-title>
							<ConnectionSummary v-for="(item, j) in connection" :key="`${key}_${i}_${j}`" :connection="item"/>
						</v-list-item-content>
					</v-list-item>
				</template>
				
			</v-list>
		</v-row>
	</v-container>
</template>

<script>

import {mapGetters, mapState} from 'vuex';
import ConnectionSummary from '@/components/util/ConnectionSummary';

export default {
	name: "Summary",

	components: {
		ConnectionSummary
	},

	computed: {
		...mapGetters('configuration', ['groupedConnections']),
		...mapState('configuration', ['inputsVisible', 'outputsVisible', 'inputs', 'outputs', 'loaded']),
		visible()
		{
			//set opacity if any integration lines are visible
			return (!this.inputsVisible && !this.outputsVisible);
			
		},
		count()
		{
			return Object.keys(this.groupedConnections).length;
		}
	},

	data: () => ({

		icons: {
			Users: 'mdi-account-multiple',
			Devices: 'mdi-devices',
			default: 'mdi-transit-connection-horizontal'
		}
	}),
};
</script>
