import config from '@/api/config';
import _ from 'lodash';

const state = () => ({
	list: [],
	item: {},
	loaded: false
});


const getters = {
};

const mutations = {
	setProjects: (state, integrations) => {
		state.list = integrations;
	},
	setProject: (state, integration) => {
		state.item = { ...state.item, ...integration}
	},
	setLoaded: (state, loaded) => {
		state.loaded = loaded;
	},
};

const actions = {
	load: async ({commit, rootGetters}) => {

		const integrations = await config.getAvailableIntegrations();

		//we only want the ones we own in the development screen
		const organizationId = rootGetters['user/organizationId'];
		commit('setProjects', _.filter(integrations, [ 'owner', organizationId ]));
		commit('setLoaded', true);
	},
	select: async ({commit, state, dispatch}, integrationId) => {
		
		if(!state.loaded)
		{
			await dispatch('load');
		}

		const integration = _.find(state.list, [ 'integrationId', integrationId ]);
		commit('setProject', integration);
		
	},
	addProject: async ({dispatch}, { name, description }) => {

		await config.addIntegration(name, description);
		await dispatch('load');
		
	},

	updateProject: async ({dispatch}, { integrationId, name, description }) => {

		await config.updateIntegration(integrationId, name, description);
		await dispatch('load');
		await dispatch('select', integrationId);
	},

	uploadImage: async ({dispatch}, { integrationId, type, data }) => {

		await config.uploadImage(integrationId, type, data);
		await dispatch('load');
		await dispatch('select', integrationId);
		
	}
};


export default {
	namespaced: true,	
	state,
	getters,
	mutations,
	actions
};