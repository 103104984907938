<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Enable {{item.name}}</v-card-title>		
		<v-card-text class="text-left">		
			<v-form id="form" ref="form" v-model="valid">
				<v-row v-if="item.implementation && item.implementation.oauth">
					<v-col cols="12">
						This integration uses OAuth pairing to automatically retrieve a token. Click the Authorize button below to get started.
					</v-col>
					<v-col cols="12">
						<v-btn target="self" color="primary" @click="authorize">Authorize</v-btn>
					</v-col>
					<v-col v-if="polling" cols="12" class="caption">
						Checking for token...
					</v-col>
					<v-col cols="12" class="mt-5">
						<v-text-field v-model="refreshToken" readonly label="Token" outlined :rules="refreshTokenRules"/>
					</v-col>
				</v-row>
				<v-row v-if="item.implementation && settings && credentials">
					<v-jsf v-model="values" :schema="schema" :options="options" :disabled="loading"/>
				</v-row>	
			</v-form>	
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>
		<v-card-actions>
			<v-btn text :disabled="loading" @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" text :disabled="!valid || loading" @click="save">Enable</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>
import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'
import { mapState } from 'vuex';

export default {
	name: "EnableIntegration",

	props: {
		item: Object
	},

	components: {
		DialogError,
		VJsf
	},

	watch: {
		loading(value)
		{
			const elems = document.getElementById('form').getElementsByTagName('input');
			_.each(elems, (el)=>{
				el.disabled = value;
			});
		},
		item(value)
		{
			if(value)
			{
				this.load();
			}
		}
	},

	computed: {
		schema()
		{

			const credentials = _.cloneDeep(this.item.implementation.credentials);
			credentials.title = 'Credentials';

			_.each(credentials.properties, (property, i) => {
				if(this.credentials[i])
				{
					property.default = this.credentials[i];
				}
			});

			const settings = _.cloneDeep(this.item.implementation.configuration);
			settings.title = 'Settings';

			_.each(settings.properties, (property, i) => {
				if(this.settings[i])
				{
					property.default = this.settings[i];
				}
			});

			const schema = {
				type: 'object',
				properties: {
					credentials, settings
				}
			}
		
			this.setPassword(schema.properties);
			return schema;
		}
	},
	mounted()
	{
		this.load();
	},

	methods: {
		authorize()
		{
			const oauthUrl = this.getOauthUrl();
			window.open(oauthUrl, '_blank');
			this.polling = new Date();
			this.poll();
		},

		poll()
		{
			//start polling for refresh token
			this.timeout = setTimeout(async ()=>{

				try {
					const { integrationId } = this.item;
					const { key } = this.item.implementation.oauth;
					const { value, updated } = await this.$store.dispatch('oauth/poll', { integrationId, key });

					const date = new Date(updated);

					//value has been updated
					if(date > this.polling)
					{
						this.refreshToken = value;
						this.polling = null;
					}
					

				} catch(e) {}

				if(!this.refreshToken)
				{
					this.poll();
				}

			}, 5000);

		},
		setPassword(properties)
		{
			_.each(properties, (property)=>{
				if(property.type === 'object')
				{
					this.setPassword(property.properties);
				} else if(property.type === 'string' && property.format === 'password')
				{
					property['x-props'] = { type: 'password' };
				}
			});
		},
		async save()
		{
			
			const { credentials, settings } = this.values;

			this.loading = true;

			try {
				await this.$store.dispatch('configuration/enableIntegration', {
					integrationId: this.item.integrationId,
					settings,
					credentials
				});
			} catch(e) {
				const errors = [];
				if(Array.isArray(e.response.data))
				{
					_.each(e.response.data, (error)=>{
						const name = _.startCase(_.replace(error.instancePath));
						errors.push(`${name}: ${error.message}`);
					});
					
				}
				//console.error(e);
				this.$refs.error.show(`Could not enable integration.${errors.join(', ')}`);
				this.loading = false;
				return;
			}

			this.close();

		},
		async load()
		{
			this.settings = await this.$store.dispatch('configuration/getSettings', this.item.integrationId) || {};
			this.credentials = await this.$store.dispatch('configuration/getCredentials', this.item.integrationId) || {};
			this.state = await this.$store.dispatch('oauth/getState', { integrationId: this.item.integrationId});
		},

		close()
		{
			clearTimeout(this.timeout);
			this.polling = null;

			this.loading = false;
			this.$set(this.values, {});
			this.$emit('close');
		},

		getOauthUrl()
		{
			const { url } = this.item.implementation.oauth
			return `${url}&state=${this.state}`
		}

		
	},

	data: () => ({
		loading: false,
		values: {},
		valid: false,
		credentials: null,
		settings: null,
		refreshToken: null,
		polling: null,
		refreshTokenRules: [
			v => !!v || 'Refresh token is required and is set during pairing process'
		],
		options: {
			initialValidation: 'all',
			sectionsClass: 'col-12',
			sectionsTitlesClasses: ['subtitle-2']
		}
	}),
};
</script>