<template>
	<v-stepper v-model="index" class="text-left">
		<v-stepper-header>
			<template v-for="(step, i) in steps" >
				<v-stepper-step :key="'header' + i" :complete="index > i+1" :step="i+1">
					{{step.header.title}}
					<small class="mt-1">{{step.header.subtitle}}</small>
				</v-stepper-step>
				<v-divider v-if="i < steps.length - 1" :key="'divider' + i"></v-divider>
			</template>
		</v-stepper-header>
		<DialogError ref="error"/>
		<template v-for="(step, i) in steps" >
			<v-stepper-content :key="i" :step="i+1">
				<component :is="step.component" v-if="index === (i+1)" v-model="value" :item="item" :isDisclosure="isDisclosure" class="mb-10" />
				<v-row class="px-3">
					<v-btn text @click="cancel"> 
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn color="primary" text :disabled="!valid" @click="next()">
						{{buttonLabel}}
					</v-btn>
				</v-row>
			</v-stepper-content>
		</template>
	</v-stepper>
</template>

<script>

import ConnectionSource from '@/components/connections/ConnectionSource';
import ConnectionDestination from '@/components/connections/ConnectionDestination';
import ConnectionOptions from '@/components/connections/ConnectionOptions';
import ConnectionConfirm from '@/components/connections/ConnectionConfirm';
import DialogError from '@/components/util/DialogError';
import { mapGetters } from 'vuex';


export default {
	name: "ConnectionsDialog",

	props: {
		item: Object,
	},

	components: {
		DialogError
	},

	computed: {
		valid()
		{
			return this.steps[this.index-1].validate(this);
		},
		buttonLabel()
		{
			return (this.lastStep) ? 'Finish' : 'Next';
		},
		lastStep()
		{
			return (this.index === this.steps.length);
		},
		...mapGetters('user', [ 'isCoveredEntity' ]),

		isDisclosure()
		{
			//need item and destination first
			if(!this.item || !this.value.destination)
			{
				//default to true - dont ever want to miss
				return true;
			}
			
			console.log(`Setting isDisclosure. Covered entity ${this.isCoveredEntity} Source HIPAA ${this.item.implementation.hipaa} Destination HIPAA ${this.value.destination.output.implementation.hipaa}`);

			return !!(
				//source is HIPAA
				this.item.implementation.hipaa &&
				//destination is not
				!this.value.destination.output.implementation.hipaa &&
				//organization has BAA
				this.isCoveredEntity
			);

		}
	},
	methods: {
		async next()
		{
			if(!this.lastStep)
			{
				this.index++;
			} else {

				try {
					if(this.isDisclosure)
					{
						await this.$store.dispatch('hipaa/logConnection', {
							from: this.item.integrationId,
							to: this.value.destination.output.integrationId,
							purpose: this.value.hipaaPurpose
						});
					}

					await this.$store.dispatch('configuration/addConnection', {
						from: this.item.integrationId,
						to: this.value.destination.output.integrationId,
						cls: this.value.input.cls
					});
				} catch(e) {
					console.error(e);
					this.$refs.error.show('Could not create connection. Please try again later.');
					return;
				}

				this.reset();
				this.$emit('close');
			}
		},
		cancel()
		{
			this.reset();
			this.$emit('close');
		},
		reset()
		{
			this.index = 1;
			this.$set(this, 'value', {});

		}
	},

	mounted()
	{
		this.reset();
	},

	data: () => ({
		value: {},
		index: 1,
		steps: [
			{
				header: {
					title: 'New Connection',
					subtitle: 'Lets get started'
				},
				component: ConnectionSource,
				validate: (vm) => {
					return !!vm.value.input;
				}
			},
			{
				header: {
					title: 'Destination',
					subtitle: 'Where to?'
				},
				component: ConnectionDestination,
				validate: (vm) => {
					return !!vm.value.destination;
				}
			},
			//TODO - implement filters
			/*{
				header: {
					title: 'Options',
					subtitle: 'Customize flow'
				},
				component: ConnectionOptions,
				validate: (vm) => {
					return !!vm.value.options;
				}
			},*/
			{
				header: {
					title: 'Confirm',
					subtitle: 'Finalize it all'
				},

				component: ConnectionConfirm,

				validate: (vm) => {
					//dont allow to proceed if is disclosure and has not been confirmed
					return (!vm.isDisclosure || (vm.value.hipaaConfirm === true && vm.value.hipaaPurpose &&  vm.value.hipaaPurpose.length > 0));
				}
			}
		]
	}),
};
</script>
