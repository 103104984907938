<template>
	<v-card class="pa-3">
		<DialogError ref="error"/>
		<v-card-title>Disable {{item.name}}</v-card-title>
		<v-card-text class="text-left">
			<v-row>
				Are you sure you want to disable this integration?
			</v-row>
			<v-row>
				Settings and connections will be preserved, so it can be re-enabled in the future. However, data will stop processing when disabled.
			</v-row>
		</v-card-text>
		<v-progress-linear v-show="loading" indeterminate/>
		<v-card-actions>
			<v-btn text :disabled="loading" @click="close">Cancel</v-btn>
			<v-spacer/>
			<v-btn color="primary" :disabled="loading" @click="save">Disable</v-btn>
		</v-card-actions>
	</v-card>	
</template>

<script>

import _ from 'lodash';
import DialogError from '@/components/util/DialogError';

export default {
	name: "DisableIntegration",

	props: {
		item: Object
	},

	components: {
		DialogError
	},

	methods: {
		async save()
		{
			this.loading = true;
			try {
				await this.$store.dispatch('configuration/disableIntegration', this.item.integrationId);
			} catch(e) {
				console.error(e);
				this.$refs.error.show('Could not disable integration. Please try again later.');
				this.loading = false;
				return;
			}

			this.close();

		},
		close()
		{
			this.loading = false;
			this.selected = null;
			this.$emit('close');
		}
	},

	data: () => ({
		loading: false
	}),
};
</script>