
<template>
	<div>
		<template v-for="(property, i) in schema">
			<v-row :key="i" dense v-if="show(i)">
				<v-col cols="2">	
					{{getLabel(i)}}
				</v-col>
				<v-col cols="10">
					<div v-if="typeof property === 'object'">
						<SchemaInfoNode :schema="property"/>
					</div>
					<div v-else>{{property}}</div>
				</v-col>
			</v-row>
			
		</template>
	</div>
</template>

<script>

import _ from 'lodash';

export default {
	name: "SchemaInfoNode",

	

	props: {
		schema: [Object, Array]
	},

	methods: {
		show(key)
		{
			return !_.startsWith(key, '_');
		},
		getLabel(text)
		{
			return _.startCase(text);
		},
		truncateText(text, maxLength) {
			if (text.length > maxLength) {
				return text.slice(0, maxLength) + '...';
			}
			return text;
		}
	},

	data: () => ({
	}),
};
</script>
